import AppMuiCard from "../../../../../core/components/cards/AppCard";
import {Grid} from "@material-ui/core";
import React from "react";
import {Button} from "@progress/kendo-react-buttons";


export const QuoteSummaryCard = (props) => {

    const {
        formValues,
        formData,
        setShipmentContentsOpen,
    } = props;

    const formattedValues = {
        pickupDate: formValues.pickupDate ?
            new Intl.DateTimeFormat('en-US').format(formValues.pickupDate)
            : null,
        originZipPostal: formValues?.originZipPostal,
        originLocationType: formValues.originLocationType ?
            formData.originLocationTypes.find(x => x.id === formValues.originLocationType)?.display
            : null,
        pickupSite: formValues.pickupSite ?
            formData.pickupSiteOptions.find(x => x.id === formValues.pickupSite)?.display
            : null,
        originAccessorials: formValues.originAccessorials.map((id, idx) => {
            return <span
                key={idx}>{formData.originAccessorialOptions.find(option => option.id === id)?.display}, </span>;
        }),
        destinationZipPostal: formValues?.destinationZipPostal,
        destinationLocationType: formValues.destinationLocationType ?
            formData.destinationLocationTypes.find(x => x.id === formValues.destinationLocationType)?.display
            : null,
        deliverySite: formValues.deliverySite ?
            formData.deliverySiteOptions.find(x => x.id === formValues.deliverySite)?.display
            : null,
        destinationAccessorials: formValues.destinationAccessorials.map((id, idx) => {
            return <span
                key={idx}>{formData.destinationAccessorialOptions.find(option => option.id === id)?.display}, </span>;
        }),
        totalWeight: formValues?.totalWeight,
        isHasMat: Object.values(formValues.shipmentContents).find(x => x.isHazMat === true) ? "Yes" : "No",
    };

    if(formValues.protectFromFreeze === true) {
        formattedValues.originAccessorials.unshift(<span
            key={99}>Protect From Freeze, </span>)
    }

    return (
        <Grid item xs={12} md={12} lg={12}>
            <AppMuiCard title={'Quote Summary'}>
                <Grid container className={"quote-summary-card"}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <span>Pickup Date: </span>
                        <span className={"highlighted"}>{formattedValues.pickupDate}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2}>
                        <span>P/U Zip/Postal: </span>
                        <span className={"highlighted"}>{formattedValues.originZipPostal}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2}>
                        <span>P/U Location Type: </span>
                        <span className={"highlighted"}>{formattedValues.originLocationType}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={3}>
                        <span>Non Commercial P/U Site: </span>
                        <span className={"highlighted"}>{formattedValues.pickupSite}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={5}>
                        <span>P/U Accessorials: </span>
                        <span className={"highlighted"}>{formattedValues.originAccessorials}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2}>
                        <span>Del. Zip/Postal: </span>
                        <span className={"highlighted"}>{formattedValues.destinationZipPostal}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2}>
                        <span>Del. Location Type: </span>
                        <span className={"highlighted"}>{formattedValues.destinationLocationType}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={3}>
                        <span>Non Commercial Del. Site: </span>
                        <span className={"highlighted"}>{formattedValues.deliverySite}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={5}>
                        <span>Del. Accessorials: </span>
                        <span className={"highlighted"}>{formattedValues.destinationAccessorials}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2}>
                        <span>Total Weight:  </span>
                        <span className={"highlighted"}>{formattedValues.totalWeight} lbs</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2}>
                        <Button
                            onClick={setShipmentContentsOpen}
                            fillMode={"flat"}
                            className={"quote-summary__view-contents-button"}
                        >
                            View Contents
                        </Button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={3}>
                        <span>Haz-Mat  </span>
                        <span className={"highlighted"}>{formattedValues.isHasMat}</span>
                    </Grid>
                    { formattedValues.protectFromFreeze &&
                        <Grid item xs={6} sm={6} md={6} lg={2}>
                            <p className={"carrier-summary-new-line"}>
                                Protect From Freeze:{' '}
                                <span className={"carrier-summary-card highlighted"}>
                                    {formattedValues.protectFromFreeze}
                                </span>
                            </p>
                        </Grid>
                    }
                </Grid>
            </AppMuiCard>
        </Grid>
    );
};