import FullWidthLayout from '../../core/layouts/FullWidthLayout';
import {usePageTitle} from '../../crm/components/customHooks/misc/usePageTitle';
import LeftNav from './LeftNav';
import {Grid} from "@material-ui/core";
import AppTitleBar from "../../core/components/AppTitleBar";
import AppAlertStatic from "../../core/components/AppAlertStatic";
import React from "react";

export const AgencyAdmin = () => {
    usePageTitle("TGF: Agency Admin Page");

    return (
        <FullWidthLayout SideNav={LeftNav}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <AppTitleBar
                        title="Agency Admin"
                    />
                </Grid>
                <Grid item xs={12}>
                    <AppAlertStatic title="Welcome to the Agency section.">
                        Use the navigation to the left to change agency settings.
                    </AppAlertStatic>
                </Grid>
            </Grid>
        </FullWidthLayout>
    );
};
