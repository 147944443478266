import {
    Home,
    People
} from '@material-ui/icons';
import { useEffect, useState } from 'react';

import ComponentBuilder from "../../core/ComponentBuilder";
import AppSideBar from '../../core/components/AppSideBar';

// const isDashboardSelected = () => window.location.pathname.startsWith('/agency/dashboard');

const topAdminNavItem = { title: 'Admin', icon: Home, key: 'admin', href: '/agency' };

const baseLinks = [
    { title: 'Manage Agency Users', icon: People, key: 'manage-agency-users', href: '/agency/users' },
    // { title: 'Dashboard', icon: Dashboard, key: 'dashboard', href: '/agency/dashboard/all', isSelected: isDashboardSelected },
];

const LeftNav = ({ getFeatureState }) => {
    const [open, setOpen] = useState(true);
    const [menuItems, setMenuItems] = useState([]);

    useEffect(() => {
        const findAdditionalLinks = () => {
            const links = [...baseLinks];
            links.sort((a, b) => a.title.localeCompare(b.title));
            links.unshift(topAdminNavItem);
            return links;
        };

        async function getFeatureStateFlagged() {
            const featureFlags = await getFeatureState();
            const links = findAdditionalLinks(featureFlags);
            setMenuItems(links);
        }
        getFeatureStateFlagged();

    }, [getFeatureState]);

    const handleDrawerOpen = () => setOpen(true);

    const handleDrawerClose = () => setOpen(false);

    return (
        <AppSideBar
            menuList={menuItems}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
            open={open}
        />
    );
};

export default ComponentBuilder
    .wrap(LeftNav)
    .stateToProps((state, ownProps) => ({
    }))
    .dispatchToProps((shell, dispatch, getState) => {
        return {
            async getFeatureState() {
                return await shell.gateway.communicator.get('/security/featureFlags');
            }
        };
    })
    .build();
