import {Grid} from "@material-ui/core";
import {usePageTitle} from "../../../../../crm/components/customHooks/misc/usePageTitle";
import React, {useState} from "react";
import AppAlertStatic from "../../../../../core/components/AppAlertStatic";
import {ConsigneeDetailsCard} from "./includes/ConsigneeDetailsCard";
import {ReferenceNumbersCard} from "./includes/ReferenceNumbersCard";
import {GeneralBolNotesCard} from "./includes/GeneralBolNotesCard";
import {FinancialsCard} from "./includes/FinancialsCard";
import {CarrierSummaryCard} from "./includes/CarrierSummaryCard";
import {ShipperDetailsCard} from "./includes/ShipperDetailsCard";
import useFieldValidator from "../../../../../crm/components/customHooks/form/validation/useFieldValidator2.0";
import {bookingFormValidationSchema} from "./includes/validation/bookingFormValidationSchema";
import {ShipmentContents} from "../ShipmentContents/ShipmentContents";
import TgfButtonBasic from "../../../../../core/kendo/buttons/TgfButtonBasic";
import {HazmatEmergencyContactCard} from "../HazmatEmergencyContactCard/HazmatEmergencyContactCard";

export const BookingForm = (props) => {
    usePageTitle("TGF: Booking");
    const {
        formValues,
        applyFormValue,
        formData,
        stepForward,
        type,
        setFormValues,
        setIsDirty,
        shipmentContentsAreValid,
        setShipmentContentsAreValid,
        defaultShipmentContent,
        activeStepIdx,
    } = props;

    const [isValid, setIsValid] = useState(false);

    const fieldErrors = useFieldValidator(bookingFormValidationSchema, formValues, setIsValid);

    const filterShipperConsignee = (filter) => {
        return {
            ...filter,
            $or :  [
                { companyIsDisabled: false },
                { companyIsDisabled: null }
            ],
            affiliateCompanyId: formValues.customer.companyId,
            companyIsInactive: false,
            primaryContactId: {
                $ne: null
            }
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <CarrierSummaryCard
                    formValues={formValues}
                    applyFormValue={applyFormValue}
                    formData={formData}
                />
            </Grid>
            <Grid item>
                <AppAlertStatic
                    style={{fontSize: 10}}
                    title={
                        "Newly entered addresses below will automatically be saved as a " +
                        "Shipper/Consignee under the involved customer. The information entered " +
                        "in the Contact Name field will be saved as the primary contact and the " +
                        "entered phone number will be saved as the Business Phone."
                    }
                    color={"warning"}
                />
            </Grid>
            <Grid container item spacing={2}>
                <Grid item xs={12} md={4}>
                    <ShipperDetailsCard
                        formValues={formValues}
                        shipper={formValues.shipper}
                        setFormValues={setFormValues}
                        setIsDirty={setIsDirty}
                        fieldErrors={fieldErrors}
                        filterShipperConsignee={filterShipperConsignee}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ConsigneeDetailsCard
                        formValues={formValues}
                        setFormValues={setFormValues}
                        applyFormValue={applyFormValue}
                        consignee={formValues.consignee}
                        setIsDirty={setIsDirty}
                        fieldErrors={fieldErrors}
                        filterShipperConsignee={filterShipperConsignee}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ReferenceNumbersCard
                        formValues={formValues}
                        setFormValues={setFormValues}
                    />
                </Grid>
            </Grid>
            <Grid item>
                <AppAlertStatic
                    style={{fontSize: 10}}
                    title={
                        `For any displayed shipment contents below to be added to the involved customer's Product Catalog, the "Log" column's toggle must be toggled on accordingly.`
                    }
                    color={"warning"}
                />
            </Grid>
            <Grid item xs={12}>
                <ShipmentContents
                    type={type}
                    formValues={formValues}
                    formData={formData}
                    setFormValues={setFormValues}
                    setIsDirty={setIsDirty}
                    shipmentContentsAreValid={shipmentContentsAreValid}
                    setShipmentContentsAreValid={setShipmentContentsAreValid}
                    defaultShipmentContent={defaultShipmentContent}
                    activeStepIdx={activeStepIdx}
                />
            </Grid>
            <Grid item xs={12} md={12} lg={4} style={{height: "fit-content"}}>
                <GeneralBolNotesCard
                    formValues={formValues}
                    applyFormValue={applyFormValue}
                    formData={formData}
                />
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <FinancialsCard
                            formValues={formValues}
                            applyFormValue={applyFormValue}
                            fieldErrors={fieldErrors}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        {formValues.isHazMat &&
                            <HazmatEmergencyContactCard
                                formValues={formValues}
                                applyFormValue={applyFormValue}
                                fieldErrors={fieldErrors}
                            />
                        }
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={12} md={12} lg={4}>
                <Grid container justifyContent={"center"}>
                    <Grid item>
                        <TgfButtonBasic
                            onClick={stepForward}
                            className={"booking-form__continue-btn"}
                            disabled={(!isValid)}
                            style={{backgroundColor: '#075ebc', border: "none", fontWeight: 500}}
                        >
                            CONTINUE / REVIEW SHIPMENT
                        </TgfButtonBasic>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
