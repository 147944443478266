import React, {forwardRef, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Grid} from "@material-ui/core";
import {Delete, Edit} from "@material-ui/icons";
import AppIconButton from "../../../../core/components/AppButton/AppIconButton";
import AgentTooltipComponent from "../../../../crm/components/agent-tooltip/AgentTooltipComponent";
import TgfDataGrid from "../../../../core/kendo/dataGrid/TgfDataGrid";
import {useParams} from "react-router-dom";
import AddEditProductModal from "./AddEditProductModal";
import ConfirmationModal from "../../../../core/kendo/dialog/ConfimationModal";

const changeCell = (props, onClickEditRow, onClickDeleteRow) => {
    return <td>
        <AppIconButton
            Icon={Edit}
            tooltip={`Edit Product`}
            onClick={() => onClickEditRow(props.dataItem)}
        />
        <AppIconButton
            Icon={Delete}
            color={"error"}
            tooltip={`Delete Product`}
            onClick={() => onClickDeleteRow(props.dataItem)}
        />
    </td>
}

const ProductsGrid = forwardRef((props, ref) => {

    const {reloadData} = props;
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [addEditProductModalOpen, setAddEditProductModalOpen] = useState(false);
    const [deleteProductModalOpen, setDeleteProductModalOpen] = useState(false);
    const [pageReady, setPageReady] = useState(false);

    const personalDispatch = useDispatch();
    const urlParams = useParams();
    const companyId = parseInt(urlParams?.id);

    const loadProductCatalog = async (odata) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadProductCatalog'));
            const result = await window.shell.gateway.getProductCatalog(odata, companyId);
            setPageReady(true);
            return result;
        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadProductCatalog'));
        }
    }

    useEffect(() => {
        if (pageReady) {
            ref?.current?.refresh();
        }
    }, [pageReady]);

    const onClickEditRow = (data) => {
        delete Object.assign(data, {
            productId: data.id
        }).id;

        setSelectedProduct(data);
        setAddEditProductModalOpen(true);
    };

    const onClickDeleteRow = (data) => {
        delete Object.assign(data, {
            productId: data.id
        }).id;

        setSelectedProduct(data);
        setDeleteProductModalOpen(true);
    };

    const handleDeleteProduct = async () => {
        try {
            console.log('selectedProduct', selectedProduct)
            personalDispatch(window.shell.actions.sys.processStart('deleteProductData'));

            await window.shell.gateway.deleteProduct(
                companyId,
                selectedProduct.productId
            );

            reloadData();
        } catch (e) {
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Failed To Delete Product',
                color: 'error'
            }));
            console.log(e);
        } finally {
            // props.reloadData();
            setDeleteProductModalOpen(false);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Product Deleted'
            }));
            personalDispatch(window.shell.actions.sys.processComplete('deleteProductData'));
        }
    }

    const gridColumns = [
        {
            title: 'Product Description',
            field: 'productDescription',
            type: 'custom',
            cell: (props) => <td className={"k-table-td show-all-text"}><span>{(props.dataItem.productDescription ?? '')}</span></td>
        },
        {
            title: 'Internal Product Notes',
            field: 'internalProductNotes',
            type: 'custom',
            cell: (props) => <td className={"k-table-td show-all-text"}><span>{(props.dataItem.internalProductNotes ?? '')}</span></td>
        },
        {
            title: 'Class',
            field: 'productClass',
            type: 'text',
            width: 80
        },
        {
            title: 'NMFC',
            field: 'nmfcCode',
            type: 'text',
            width: 80
        },
        {
            title: ' ',
            field: '',
            type: 'custom',
            cell: (props) => changeCell(props, onClickEditRow, onClickDeleteRow),
            width: 80,
            sortable: false
        },
    ];

    return (
        <Grid container spacing={2}>
            {addEditProductModalOpen &&
                <AddEditProductModal
                    showNewProduct={false}
                    companyId={companyId}
                    setAddEditProductModalOpen={setAddEditProductModalOpen}
                    productId={selectedProduct.productId}
                    originalProductDescription={selectedProduct.productDescription}
                    originalInternalProductNotes={selectedProduct.internalProductNotes}
                    originalNmfc={selectedProduct.nmfcCode}
                    originalProductClass={selectedProduct.productClass}
                    reloadData={reloadData}
                />
            }
            {deleteProductModalOpen &&
                <ConfirmationModal
                    title={'Confirm Product Deletion'}
                    description={'Are you sure you want to delete this product?'}
                    action={handleDeleteProduct}
                    onClose={() => setDeleteProductModalOpen(false)}
                />
            }
            <Grid item xs={12}>
                <TgfDataGrid
                    className={"open-load-board_shipment-listing-grid"}
                    ref={ref}
                    onLoadDataPage={loadProductCatalog}
                    gridColumns={gridColumns}
                    sort={[{field: "productDescription", dir: "asc"}]}
                    pageable={{
                        pageSize: 20,
                        buttonCount: 10,
                        pageSizes: [5, 10, 20, 50, 100, 500]
                    }}
                />
            </Grid>
        </Grid>
    )
})

export default ProductsGrid
