import AppMuiCard from "../../../../../core/components/cards/AppCard";
import React from "react";
import {Grid} from "@material-ui/core";
import DateUtils from "../../../../../core/utils/dateUtils/dateUtils";
import InfoIconTooltip from "../../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";


export const RefNumberInfo = (props) => {

    const {
        ltlTracking,
        ltlShipmentDetails,
    } = props.data;

    const formattedValues = {
        createdOnDate: DateUtils.getDisplayDateFromDateString(ltlShipmentDetails?.createdDate),
        gtzBolNumber: ltlShipmentDetails?.thirdPartyBolNumber,
        customerReferenceNumber: ltlShipmentDetails?.ShipmentStatus?.ReferenceNo,
        carrierPickupNumber: ltlTracking?.ShipmentStatus?.CarrierPickupNumber,
        originPickupNumber: ltlTracking?.ShipmentStatus?.PoNo,
        carrierProNumber: ltlTracking?.ShipmentStatus?.PRONo
    }

    return (
        <AppMuiCard title={<div className={"card-title"}><span>Reference Number Information</span> <InfoIconTooltip title={"TBD - Mike will decide"} /></div>}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    Created on: <strong>{formattedValues?.createdOnDate}</strong>
                </Grid>
                <Grid item xs={12}>
                    GTZ #: <strong>{formattedValues?.gtzBolNumber}</strong>
                </Grid>
                <Grid item xs={12}>
                    Customer Ref # / TGF Ship #: <strong>{formattedValues?.customerReferenceNumber}</strong>
                </Grid>
                <Grid item xs={12}>
                    Carrier Pickup #: <strong>{formattedValues?.carrierPickupNumber}</strong>
                </Grid>
                <Grid item xs={12}>
                    Origin P/U #: <strong>{formattedValues?.originPickupNumber}</strong>
                </Grid>
                <Grid item xs={12}>
                    Carrier Pro #: <strong>{formattedValues?.carrierProNumber}</strong>
                </Grid>
            </Grid>
        </AppMuiCard>
    )
}
