import AppMuiCard from "../../../../../core/components/cards/AppCard";
import {Grid} from "@material-ui/core";
import TgfToggle from "../../../../../core/kendo/form/inputs/TgfToggle";
import AppAlertStatic from "../../../../../core/components/AppAlertStatic";
import React from "react";

export const AdditionalDetailsCard = ({formValues, applyFormValue}) => {

    const handleChange = (e) => {
        applyFormValue(e.target.name, e.target.value)
    }

    return (
        <AppMuiCard title={'Additional Details and Information'}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={3}>
                    <TgfToggle
                        label={"Protect From Freeze?"}
                        labelPosition={"top"}
                        id={"protectFromFreeze"}
                        name={"protectFromFreeze"}
                        checked={formValues.protectFromFreeze}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={9}>
                    <AppAlertStatic
                        title={"Our LTL Golden Rules (available on the Help Page) MUST be followed."}
                        color={"warning"}
                    />
                </Grid>
            </Grid>
        </AppMuiCard>
    )
}