import React, {useEffect, useRef} from "react";
import TgfDialog from "../../../../core/kendo/dialog/TgfDialog";
import "./agencyUser.scss";
import TgfFormComponent from "../../../../core/kendo/form/tgfFormComponent/TgfFormComponent";
import {agencyUserSchema} from "./formValidationSchemas/agencyUserSchema";
import {TgfFormGrid} from "../../../../core/kendo/form/tgfFormComponent/fields/TgfFormGrid";
import {TgfDropDownListFormField} from "../../../../core/kendo/form/tgfFormComponent/fields/TgfDropDownListFormField";
import {TgfCustomFormField} from "../../../../core/kendo/form/tgfFormComponent/fields/TgfCustomFormField";
import {TgfButtonFormField} from "../../../../core/kendo/form/tgfFormComponent/fields/TgfButtonFormField";
import _ from "lodash";
import {useDispatch} from "react-redux";

export const EditAgencyUserModal = (props) => {
    const {
        closeEditAgencyUserModal,
        incomingAssociate,
        loadAgencyAccessFilters,
        loadHeadOfAgencyCount,
        agencyAccessFilters,
        headOfAgencyCount,
        openDeactivationModal,
    } = props;
    let selectedAssociate = incomingAssociate;

    const ref = useRef();
    const personalDispatch = useDispatch();

    // initial load
    useEffect(() => {
        ref.current?.loadForm();
        ref.current?.setIsEditMode(true);
        loadAgencyAccessFilters();
        loadHeadOfAgencyCount();
    }, []);

    useEffect(() => {
        if (selectedAssociate.isHeadOfAgency) {
            selectedAssociate.accessFilterId = 0;
        }
    }, [agencyAccessFilters]);

    const loadFormData = (loadValue) => {
        const formValues = {
            entity: _.cloneDeep(loadValue.selectedAssociate),
            originalValues: _.cloneDeep(loadValue.selectedAssociate),
            additionalData: {
                headOfAgency: headOfAgencyOptions,
                accessFilter: agencyAccessFilters,
            },
        };

        return formValues;
    };

    const headOfAgencyOptions = [
        {id: true, display: "Yes"},
        {id: false, display: "No"},
    ];

    const gridList = [
        new TgfFormGrid(
            {
                spacing: 3,
                style: {paddingTop: 30, paddingLeft: 9},
                fieldList: [
                    new TgfCustomFormField({
                        columnWidth: 12,
                        displayElementFunction: (isEditMode, data, validationErrors) => {
                            return <>
                                <label className={"modal-label"}>
                                    User:
                                </label>
                                {' '}{data.entity.firstName}{' '}{data.entity.lastName}
                            </>;
                        },
                    }),
                    new TgfDropDownListFormField(
                        {
                            columnWidth: 12,
                            id: "headOfAgency",
                            name: "headOfAgency",
                            label: "Head of Agency",
                            bindingValue: "entity.isHeadOfAgency",
                            validationBindingValue: "entity.isHeadOfAgency",
                            dataBindingValue: "additionalData.headOfAgency",
                            disabledFunction: (isEditMode, data, validationErrors) => {
                                // Not demote-able if they are the only Head of Agency or a Queen.
                                return data.entity.isHeadOfAgency && (headOfAgencyCount == 1 || data.entity.roleName == 'Queen');
                            },
                        },
                    ),
                    new TgfDropDownListFormField(
                        {
                            columnWidth: 12,
                            id: "accessFilter",
                            name: "accessFilter",
                            label: "Visibility Permission",
                            bindingValue: "entity.accessFilterId",
                            validationBindingValue: "entity.accessFilterId",
                            dataBindingValue: "additionalData.accessFilter",
                            disabledFunction: (isEditMode, data, validationErrors) => {
                                return data.entity.isHeadOfAgency;
                            },
                            isVisibleFunction: (isEditMode, data, validationErrors) => {
                                return !data.entity.isHeadOfAgency;
                            },
                        },
                    ),
                    new TgfButtonFormField({
                        columnWidth: 12,
                        variant: "text",
                        color: "default",
                        type: "submit",
                        contentFunction: (isEditMode, data, validationErrors) => {
                            return "DEACTIVATE CRM ACCESS FOR THIS USER";
                        },
                        onClickHandler: (isEditMode, data, validationErrors) => {
                            selectedAssociate = data.originalValues;
                            closeEditAgencyUserModal();
                            openDeactivationModal();
                        },
                        disabledFunction: (isEditMode, data, validationErrors) => {
                            return data.entity.isHeadOfAgency;
                        },
                        isVisibleFunction: (isEditMode, data, validationErrors) => {
                            return !data.entity.isHeadOfAgency;
                        },
                    }),
                    new TgfButtonFormField({
                        columnWidth: 2,
                        variant: "contained",
                        color: "primary",
                        type: "submit",
                        disabledFunction: (isEditMode, data, validationErrors) => {
                            const disabled = validationErrors !== null
                                || (
                                    data.originalValues.isHeadOfAgency === data.entity.isHeadOfAgency
                                    && data.originalValues.accessFilterId === data.entity.accessFilterId
                                );
                            return disabled;
                        },
                        contentFunction: (isEditMode, data, validationErrors) => {
                            return "Save";
                        },
                        onClickHandler: async () => {
                            try {
                                await ref.current?.submitForm();
                                await personalDispatch(
                                    await window.shell.actions.sys.sendSnackbarMessage(
                                        {content: 'Agency user changes saved.'},
                                    ),
                                );
                            } catch {
                                personalDispatch(
                                    await window.shell.actions.sys.sendSnackbarMessage(
                                        {content: 'Error. Please contact IT.'},
                                    ),
                                );
                            } finally {
                                closeEditAgencyUserModal();
                            }
                        },
                    }),
                    new TgfButtonFormField({
                        columnWidth: 2,
                        variant: "contained",
                        color: "",
                        type: "submit",
                        contentFunction: (isEditMode, data, validationErrors) => {
                            return "Cancel";
                        },
                        onClickHandler: (isEditMode, data, validationErrors) => {
                            selectedAssociate = data.originalValues;
                            closeEditAgencyUserModal();
                            ref.current?.loadForm();
                        },
                    }),
                ],
            },
        ),
    ];

    const handleSubmit = async (data) => {
        if (data) {
            const newAssociateValues = _.cloneDeep(data?.entity);

            newAssociateValues.actionRoleId = newAssociateValues.isHeadOfAgency ? 2 : 3;

            return await window.shell.gateway.updateAssociateSecurityContext(
                newAssociateValues.id,
                newAssociateValues.actionRoleId,
                newAssociateValues.accessFilterId,
            );
        }
    };

    return (
        <TgfDialog
            title={`Edit Agency User`}
            onClose={closeEditAgencyUserModal}
            width={600}
        >
            <TgfFormComponent
                ref={ref}
                onLoadFormData={loadFormData}
                loadFormArgument={{
                    selectedAssociate: selectedAssociate,
                }}
                validationSchema={agencyUserSchema}
                onSubmit={handleSubmit}
                preRenderComponent={<>Loading...</>}
                processName={'edit-agency-user'}
                gridList={gridList}
                {...props}
            />
        </TgfDialog>
    );
};
