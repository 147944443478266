import {Button} from "@progress/kendo-react-buttons";
import {Popover, PopoverActionsBar} from "@progress/kendo-react-tooltip";
import React, {useRef, useState} from "react";

const TgfPopover = (props) => {
    const {
        button,
        title = null,
        popoverContent,
        showCloseAction = false,
        position = "top",
    } = props;
    const anchor = useRef(null);

    const [show, setShow] = useState(false);

    const changeShowStatus = () => {
        setShow(!show)
    };

    const onMouseEnter = () => {
        setShow(true);
    };

    const onMouseLeave = () => {
        setShow(false)
    };

    return (
        <>
            <Button
                ref={anchor}
                onClick={changeShowStatus}
                fillMode={"flat"}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {button}
            </Button>
            <Popover
                show={show}
                position={position}
                anchor={anchor.current && anchor.current.element}
                title={title ? <h3><strong>{title}</strong></h3> : null}
                animate={false}
            >
                {popoverContent}
                {showCloseAction &&
                    <PopoverActionsBar>
                        <Button
                            onClick={changeShowStatus}
                            fillMode={"flat"}
                        >
                            Close
                        </Button>
                    </PopoverActionsBar>
                }
            </Popover>
        </>
    );
};

export default TgfPopover;