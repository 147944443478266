import React, {useRef, useState} from "react";
import TgfDialog from "../../../../core/kendo/dialog/TgfDialog";
import "./agencyUser.scss";
import {useDispatch, useSelector} from "react-redux";
import TgfDataGrid from "../../../../core/kendo/dataGrid/TgfDataGrid";
import {Link} from "react-router-dom";

export const InvolvedCustomersModal = (props) => {
    const {
        closeInvolvedCustomersModal,
        selectedAssociate,
    } = props;

    const personalDispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [involvedCustomers, setInvolvedCustomers] = useState();

    const ref = useRef();

    const reloadData = () => {
        ref.current.refresh();
    }

    const applyAdditionalFilters = (odata) => {
        odata.filter.and = {
            associateId: selectedAssociate.id
        };
        return odata;
    }

    const loadInvolvedCustomers = async (odata) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadInvolvedCustomers'));
            return await window.shell.gateway.retrieveInvolvedCustomers(odata, );
        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadInvolvedCustomers'));
        }
    };

    const CompanyLinkCell = (cellProps) => {
        const openLinksInNewTab = useSelector((state) => state.persona.associate.openLinksInNewTab);
        const companyId = cellProps.dataItem?.companyId;
        const companyName = cellProps.dataItem?.companyName;
        return <td>
            { companyId
                ? <Link className={"tgf-grid-link-cell"} to={`/address-book/${companyId}/details`} target={openLinksInNewTab ? "_blank" : "" }>{companyName}</Link>
                : <span style={{color: "red"}}>POSTING</span>
            }
        </td>
    };

    const IsOwnerCell = (props) => {
        return <td>
            <span>{props.dataItem?.isOwner === true ? 'Yes': 'No'}</span>
        </td>
    };

    const gridColumns = [
        {
            title: 'Company Name',
            field: 'companyName',
            type: 'custom',
            cell: CompanyLinkCell
        },
        {
            title: 'Is Owner',
            field: 'isOwner',
            type: 'custom',
            cell: IsOwnerCell
        }
    ];

    return (
        <TgfDialog
            title={`Involved Customers for ${selectedAssociate.firstName} ${selectedAssociate.lastName}`}
            onClose={closeInvolvedCustomersModal}
            width={800}>
                <TgfDataGrid
                    ref={ref}
                    className={"open-load-board_shipment-listing-grid"}
                    onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
                    onLoadDataPage={loadInvolvedCustomers}
                    gridColumns={gridColumns}
                    sort={[{field: "companyName", dir: "asc"}]}
                    pageable={{
                        pageSize: 20,
                        buttonCount: 10,
                        pageSizes: [5, 10, 20, 50, 100, 500]
                    }} />
        </TgfDialog>
    );
};
