import Core from '@atomos/core';
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import AddressBookRecordNav from '../AddressBookRecordNav';
import AppDialog from '../../../core/components/AppDialog';
import ChangeCategory from './ChangeCategory';
import ComponentBuilder from '../../../core/ComponentBuilder';
import FormFactor from '../../../core/FormFactor/FormFactor';
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";
import isNewCompany from '../../../hubs/addressBook/actions/modification/isNewCompany';
import renderDetailsForm from './renderDetailsForm';
import selectStateProvinces from '../../../hubs/support/selectors/stateProvinces';
import selectCompanyCategoryTypes from '../../../hubs/support/selectors/companyCategoryTypes';
import CategoryTypeNames from "../../../hubs/addressBook/CategoryTypeNames";
import composePageTitle from '../../../core/utils/composePageTitle';
import DisableCustomerReason from "./DisableCustomerReason";
import "./DetailsPage.scss";
import MissingOrDeniedCompanyAlert from "../MissingOrDeniedCompanyAlert.js";
import CheckIfAuthorized from "../tempSecurity/CheckIfAuthorized";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
import {useDispatch} from "react-redux";
import AgentTooltipComponent from "../../../crm/components/agent-tooltip/AgentTooltipComponent";
import DetailsFormSchema from "./DetailsFormSchema";
import DuplicateShipperConsigneeModal from "./DuplicateShipperConsigneeModal";

const LoadProcessName = 'AddressBook.LEGACYDetailsPage.Load';
const SaveProcessName = 'AddressBook.LEGACYDetailsPage.Save';
const DisableCustomerProcessName = 'AddressBook.LEGACYDetailsPage.DisableCustomer';
const LoadDisablementHistoryProcessName = 'AddressBook.LEGACYDetailsPage.LoadDisablementHistory';

const DefaultCreditLimit = 10000.00;

const DetailsPage = (props) => {

  const {
    match,
    history,
    affiliateCompany,
    candidateAffiliates,
    associates,
    companyContacts = null,
    company,
    categoryTypes = [],
    creditStatus,
    customerCategoryType,
    saveCompanyCategoryChange,
    firstShipment,
    stateProvinces,
    isAdmin,
    modifyingAssociate,
    load,
    dispose,
    saveCompany,
    searchAffiliates,
    sendSnackbarMessage,
    loadDisablementHistory,
    manualDisablement,
    disabledCompanyHistory,
    disabledCompanyHistoryCount,
    companyAutofillFields,
    saveCompanyAutofillFields
  } = props;

  const stateProvinceObjects = stateProvinces
    .map(([abbreviation, name]) => ({ abbreviation, name }));

  const companyId = match.params.id !== 'new' ?
    parseInt(match.params.id) : undefined;

  const personalDispatch = useDispatch();
  const [showChangeCategory, setShowChangeCategory] = useState(false)
  const [showConvertToCustomer, setShowConvertToCustomer] = useState(false)
  const [showOpenDisableCustomer, setShowOpenDisableCustomer] = useState(false)
  const [disabledHistoryOffset, setDisabledHistoryOffset] = useState(0);
  const [disabledHistoryLimit, setDisabledHistoryLimit] = useState(1000);
  const [disabledHistorySort, setDisabledHistorySort] = useState([['createDate', 'desc']]);
  const [collectionAssociate, setCollectionAssociate] = useState(null);

  const [duplicateShipperConsigneeOpen, setDuplicateShipperConsigneeModalOpen ] = useState(false);


  const loadAdditionalResources = async () => {
    try {
      personalDispatch(window.shell.actions.sys.processStart('loadAdditionalResourcesCompanyDetailsPage'));
      const collectionAssociateRes = await window.shell.gateway.getAssignedCollectorByCustomerId(companyId);
      setCollectionAssociate(collectionAssociateRes)

    } catch (e) {
      console.log(e);
    }
    finally {
      personalDispatch(window.shell.actions.sys.processComplete('loadAdditionalResourcesCompanyDetailsPage'));
    }
  }

  useEffect(() => {
    load(companyId);
    if (companyId) {
      loadDisablementHistory(companyId, disabledHistoryOffset, disabledHistoryLimit, disabledHistorySort);
    }
    if(companyId) loadAdditionalResources();
    return () => dispose();
  }, [companyId, disabledHistoryLimit, disabledHistoryOffset, disabledHistorySort, dispose, load, loadDisablementHistory]);

  const cancelConvertToCustomer = () => setShowConvertToCustomer(false)

  const closeConvertToCustomer = () => {
    setShowConvertToCustomer(false)

    const clonedCompany = Core.Utils.cloneDeep(company);
    clonedCompany.categoryTypeId = customerCategoryType.id;
    console.log('customer', customerCategoryType.id)
    clonedCompany.creditLimit = DefaultCreditLimit;
    const confirmText = "Lead converted to customer.";

    saveCompanyCategoryChange(clonedCompany, confirmText)
      .then(() => {
        sendSnackbarMessage({ content: 'Company converted to customer.' });
      });
  };

  const convertToCustomer = () => setShowConvertToCustomer(true);

  const convertActionButtons = [
    {
      title: "cancel",
      action: cancelConvertToCustomer
    },
    {
      title: "confirm",
      action: closeConvertToCustomer
    }
  ]

  const selectedCategoryType = company?.categoryTypeId ?
    categoryTypes.find(ct => ct.id === company?.categoryTypeId) :
    null;

  // Base data for the form.
  const companyData = Core.Utils
    .merge({}, company);

  const openChangeCategory = () => {
    setShowChangeCategory(true)
  };

  const closeChangeCategory = () => {
    setShowChangeCategory(false)
  };

  const openDisableCustomer = () => {
    setShowOpenDisableCustomer(true)
  };

  const handleChangeConfirmClick = (categoryType, reasonText) => {
    setShowChangeCategory(false);
    const confirmText = reasonText ? `Company category changed: ${reasonText}` : "Company category changed.";

    const clonedCompany = Core.Utils.cloneDeep(company);
    clonedCompany.categoryTypeId = categoryType.id;

    // set the credit limit to null if category type
    // anything but 'customer'
    categoryTypes.find(ct => ct.id === clonedCompany.categoryTypeId).name === CategoryTypeNames.Customer ?
      clonedCompany.creditLimit = 10000.00 :
      clonedCompany.creditLimit = null;

    saveCompanyCategoryChange(clonedCompany, confirmText)
      .then(() => {
        sendSnackbarMessage({ content: 'Company type changed.' });
      });
  };

  const handleDisabledCustomerHistoryPageChange = (e, page) => {
    setDisabledHistoryOffset(page * disabledHistoryLimit);
  };

  const handleDisabledCustomerHistorySortChange = (column) => {
    const [[columnName, order]] = disabledHistorySort;
    const changeOrder = (order === 'asc' && columnName === column) ? 'desc' : 'asc';

    setDisabledHistorySort([[column, changeOrder]]);
  };

  const handleDisabledCustomerHistoryLimitChange = (e) => {
    setDisabledHistoryOffset(0);
    setDisabledHistoryLimit(e.target.value);
  };

  const handleEnableCustomer = () => {
    manualDisablement(companyId, null)
      .then(() => {
        return loadDisablementHistory(companyId, disabledHistoryOffset, disabledHistoryLimit, disabledHistorySort);
      })
      .then(() => {
        sendSnackbarMessage({ content: 'Company enabled.' });
      });
  };

  const handleAutoCreditIncreaseComplete = () => {
    load(companyId)
      .then(() => {
        sendSnackbarMessage({ content: 'Company credit limit increase.' });
      });
  };

  const closeDisableCustomer = () => {
    setShowOpenDisableCustomer(false);
  };

  const handleDisableConfirmClick = (reasonText) => {
    setShowOpenDisableCustomer(false);
    manualDisablement(companyId, reasonText)
      .then(() => {
        return loadDisablementHistory(companyId, disabledHistoryOffset, disabledHistoryLimit, disabledHistorySort);
      })
      .then(() => {
        sendSnackbarMessage({ content: 'Company disabled.' });
      });
  };

  const filterCompanyCategoryTypes = () => isAdmin ?
      categoryTypes :
      categoryTypes.filter( ct => !ct.requiresAdmin);

  // Supporting objects for complex controls.
  const supportingData = {
    companyId,
    isAdmin,
    isNew: isNewCompany(companyId),
    associates,
    creditRemaining: !isNewCompany(companyId) ? creditStatus ?
      creditStatus.companyCreditRemaining :
      null : DefaultCreditLimit,
    firstShipmentDate: firstShipment ?
      firstShipment.shipmentBolDate :
      null,
    categoryTypes: filterCompanyCategoryTypes(),
    stateProvinces: stateProvinceObjects,
    associateId: company?.associateId || modifyingAssociate.id,
    selectedAssociate: company?.associate || modifyingAssociate,
    customerOwnerAssociate: company?.customerOwnerAssociate,
    disablingAssociate: company?.disablingAssociate,
    selectedCategoryType,
    selectedStateProvince: company?.stateProvince ?
      stateProvinceObjects
        .find(sp => sp.abbreviation === company?.stateProvince) : null,
    selectedAffiliate: affiliateCompany,
    companyContacts,
    candidateAffiliates,
    searchAffiliates,
    openChangeCategory,
    convertToCustomer,
    openDisableCustomer,
    disabledCompanyHistory,
    disabledCompanyHistoryCount,
    disabledHistoryOffset,
    disabledHistoryLimit,
    disabledHistorySort,
    handleDisabledCustomerHistoryPageChange,
    handleDisabledCustomerHistorySortChange,
    handleDisabledCustomerHistoryLimitChange,
    handleEnableCustomer,
    handleAutoCreditIncreaseComplete,
    companyAutofillFields,
    duplicateShipperConsigneeOpen,
    setDuplicateShipperConsigneeModalOpen
  };

  const initialValues = Core.Utils
    .merge({}, companyData, supportingData);

  const handleSubmit = async (values, formFactor) => {

    const {
      originalValues
    } = formFactor;

    const hasDisabledCompany = values.isDisabled && !originalValues.isDisabled;
    const hasEnabledCompany = !values.isDisabled;

    // If the user has opted to disable the
    // company, capture who is doing the disabling.
    if (hasDisabledCompany) {
      values.disablingAssociateId = modifyingAssociate.id;
      values.disabledDate = new Date();
    }
    // If reenabling, then clear the disable information.
    else if (hasEnabledCompany) {
      values.disablingAssociateId = null;
      values.disabledDate = null;
    }

    // set the credit limit to null if category type
    // anything but 'customer'
    if (categoryTypes.find(ct => ct.id === values.categoryTypeId).name !== CategoryTypeNames.Customer) {
      values.creditLimit = null;
    }
    saveCompany(values)
      .then((updatedCompany) => {
        loadDisablementHistory(updatedCompany.id, disabledHistoryOffset, disabledHistoryLimit, disabledHistorySort);
        if(values.companyAutofillFields && (updatedCompany?.categoryTypeId === 1 || updatedCompany?.categoryTypeId === 2)) {
          saveCompanyAutofillFields(updatedCompany.id, values.companyAutofillFields);
        }
        return updatedCompany;
      })
      .then((updatedCompany) => {
        sendSnackbarMessage({content: 'Company saved.'});
        if (!companyId) {
          history.replace(`/address-book/${updatedCompany.id}/details`);
        }
      });


  };

  const companyName = companyId ?
    company?.name : null;

  usePageTitle(companyName ? `Add: ${company?.name.substring(0, 20)}` : "Address: New");

  const title = composePageTitle('Address Book', 'Details', companyName);

  const collectionTuple = [
    collectionAssociate ?
        collectionAssociate.firstName + ' ' + collectionAssociate.lastName : 'Unassigned',
    collectionAssociate ?
        collectionAssociate.emailAddress : 'credit@tallgrassfreight.com'
  ];

  const schema = company ?
    DetailsFormSchema : null;
  return (
    <FullWidthLayout
      SideNav={isNewCompany(companyId) ? null : AddressBookRecordNav}
      title={title}
      className="address-book-styles"
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {company !== null ?
            <CheckIfAuthorized
              isAdmin={isAdmin}
              modifyingAssociate={modifyingAssociate}
              company={company} >
              {company?.categoryTypeId === 1 &&
                  <Grid item xs={4} style={{paddingBottom: 8}}>
                    <AgentTooltipComponent content={``} agentId={collectionAssociate?.id}>
                      TGF Collections Rep: <a className={'details-page_collector-link'} href={`mailto:${collectionTuple[1]}?subject=Customer: ${company.name}`}>{collectionTuple[0]}</a>
                    </AgentTooltipComponent>
                  </Grid>
              }
              <FormFactor
                initialValues={initialValues}
                schema={schema}
                onSubmit={handleSubmit}>
                {renderDetailsForm}
              </FormFactor>
            </CheckIfAuthorized>
            :
            <MissingOrDeniedCompanyAlert />}
        </Grid>
      </Grid>

      {
        showChangeCategory && <ChangeCategory
          categoryTypes={categoryTypes}
          selectedCategoryTypeId={company.categoryTypeId}
          open={showChangeCategory}
          onClose={closeChangeCategory}
          onConfirmClick={handleChangeConfirmClick}
        />
      }
      {
        showConvertToCustomer &&
          <AppDialog title="Conversion Confirmation"
            width={'xs'}
            open={showConvertToCustomer}
            onClose={closeConvertToCustomer}
            actionButtons={convertActionButtons}
          >
            Confirm <b>{company.name}</b> to customer?
          </AppDialog>
      }
      {
        showOpenDisableCustomer &&
        <DisableCustomerReason
          onClose={closeDisableCustomer}
          onConfirmClick={handleDisableConfirmClick}
        />
      }
      {duplicateShipperConsigneeOpen &&
        <DuplicateShipperConsigneeModal
          onClose={() => setDuplicateShipperConsigneeModalOpen(false)}
          currentAffiliateCustomer={affiliateCompany}
          shipperConsignee={company}

        />
      }
    </FullWidthLayout>
  );
};


export default ComponentBuilder
  .wrap(DetailsPage)
  .stateToProps((state, ownProps) => {
    return {
      affiliateCompany: state.addressBook.modification.affiliateCompany,
      candidateAffiliates: state.addressBook.modification.candidateAffiliates,
      associates: state.persona.brokerAssociates,
      company: state.addressBook.modification.company,
      companyAutofillFields: state.addressBook.modification.companyAutofillFields,
      companyContacts: state.addressBook.modification.contacts || null,
      companyFinancial: state.addressBook.modification.companyFinancial,
      creditStatus: state.addressBook.modification.creditStatus,
      customerCategoryType: state.support.customerCategoryType,
      firstShipment: state.addressBook.modification.firstShipment,
      categoryTypes: selectCompanyCategoryTypes(state),
      stateProvinces: selectStateProvinces(state),
      isAdmin: useIsAdministrator(),
      modifyingAssociate: state.persona.modifyingAssociate,
      disabledCompanyHistory: state.addressBook.modification.companyDisablementHistories,
      disabledCompanyHistoryCount: state.addressBook.modification.companyDisablementHistoriesCount
    };
  })
  .dispatchToProps((shell, dispatch, getState) => {
    return {
      async load(companyId) {
        dispatch(shell.actions.sys.processStart(LoadProcessName));

        const isNew = isNewCompany(companyId);

        const loadCompany = isNew ?
          shell.actions.addressBook.modification.loadNewCompany :
          () => shell.actions.addressBook.modification.loadCompany(companyId);

        const results = await Promise.all([
          shell.actions.addressBook.modification.loadCompanyAutofillFields(companyId),
          loadCompany(),
          !isNew ? shell.actions.addressBook.modification.loadCompanyAffiliate(companyId) : undefined,
          !isNew ? shell.actions.addressBook.modification.loadCompanyContacts(companyId) : undefined,
          !isNew ? shell.actions.addressBook.modification.loadCompanyCreditStatus(companyId) : undefined,
          !isNew ? shell.actions.addressBook.modification.loadCompanyFirstShipment(companyId) : undefined,
          !isNew ? shell.actions.addressBook.modification.loadCompanyFinancial(companyId) : undefined,
          shell.actions.persona.loadBrokerAssociates()
        ]);

        results
          .filter(Core.Utils.identity)
          .forEach(dispatch);

        dispatch(shell.actions.sys.processComplete(LoadProcessName));
      },
      async dispose() {
        dispatch(await shell.actions.addressBook.modification.dispose());
      },
      async saveCompany(company) {
        dispatch(shell.actions.sys.processStart(SaveProcessName));
        const saveCompanyAction = await shell.actions.addressBook.modification.saveCompany(company);
        dispatch(saveCompanyAction);

        const loadActions = await Promise.all([
          shell.actions.addressBook.modification.loadCompanyAutofillFields(saveCompanyAction.company.id),
            shell.actions.addressBook.modification.loadCompanyAffiliate(saveCompanyAction.company.id),
            shell.actions.addressBook.modification.loadCompanyCreditStatus(saveCompanyAction.company.id)
         ]
        );

        loadActions.forEach(action => dispatch(action));
        dispatch(shell.actions.sys.processComplete(SaveProcessName));

        return saveCompanyAction.company;
      },
      async saveCompanyCategoryChange(company, noteText) {
        dispatch(shell.actions.sys.processStart(SaveProcessName));
        const saveAction = await shell.actions.addressBook.modification.saveCompany(company);
        dispatch(saveAction);
        dispatch(await shell.actions.addressBook.modification.saveCompanyNote(saveAction.company.id, noteText))
        dispatch(shell.actions.sys.processComplete(SaveProcessName));

        return saveAction.company;
      },
      async searchAffiliates(searchTerm) {
        // Perform a simple "fetch" of the affiliates versus
        // dispatching through the redux store to rerender the form.
        return await shell.gateway.searchAffiliates(searchTerm);
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      },
      async loadDisablementHistory(companyId, offset, limit, sort) {
        dispatch(shell.actions.sys.processStart(LoadDisablementHistoryProcessName));
        dispatch(await shell.actions.addressBook.modification.loadCompanyDisablementHistory(companyId, offset, limit, sort));
        dispatch(shell.actions.sys.processComplete(LoadDisablementHistoryProcessName));
      },
      async manualDisablement(companyId, reasonText) {
        dispatch(shell.actions.sys.processStart(DisableCustomerProcessName));
        dispatch(await shell.actions.addressBook.modification.manualDisablement(companyId, reasonText));
        dispatch(shell.actions.sys.processComplete(DisableCustomerProcessName));
      },
      async saveCompanyAutofillFields(companyId, autofillFieldData) {
        dispatch(shell.actions.sys.processStart("saveCompanyAutofillFields"));
        dispatch(await shell.actions.addressBook.modification.saveCompanyAutofillFields(companyId, autofillFieldData));
        dispatch(shell.actions.sys.processComplete("saveCompanyAutofillFields"));
      }
    };
  })
  .build();
