import {Grid} from "@material-ui/core";
import React, {useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import TgfDialog from "../../../../core/kendo/dialog/TgfDialog";
import TgfDataGrid from "../../../../core/kendo/dataGrid/TgfDataGrid";

export const SelectProductModal = (props) => {
    const ref = useRef();
    const personalDispatch = useDispatch();

    const loadProducts = async (odata) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadProductCatalog'));
            if(props.selectedFreightClass) {
                const filter = {or: [
                        {productClass: props.selectedFreightClass.value},
                        {productClass: "null"}
                    ]};

                odata.filter = filter;
            }

            const result = await window.shell.gateway.getProductCatalog(odata, props.customerId, true);
            return result;
        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadProductCatalog'));
        }
    }

    useEffect(() => {
        props.setFieldValue("description", "foo");
    }, []);

    const handleSelect = async (product) => {
        try {
            if(props.shipmentType === "LTL") {
                await props.setFieldValue('description', product.productDescription);
                const freightClass = props.freightClasses.find(x => x.value === product.productClass);
                if(freightClass) {
                    props.setFieldValue('selectedFreightClass', freightClass);
                    props.setFieldValue('freightClassId', freightClass.id);
                }
                if(product.nmfcCode) {
                    props.setFieldValue('nmfc', product.nmfcCode);
                }

            } else {
                props.setFieldValue('description', product.productDescription);
            }

            props.onClose();

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('updateLockedDate'));
        }
    };

    const SelectBtnCell = (props) => {
        return (
            <td style={{textAlign: "center"}}>
                <span style={{ fontWeight: 500, color: "#708c37", cursor: "pointer"}} onClick={() => handleSelect(props.dataItem)}>SELECT</span>
            </td>
        );
    };

    const gridColumns = [
        {
            title: ' ',
            field: '',
            type: 'custom',
            cell: (props) => SelectBtnCell(props),
            width: 60,
            sortable: false
        },
        {
            title: 'Product Description',
            field: 'productDescription',
            type: 'text',
            className: "show-all-text"
        },
        {
            title: 'Internal Product Notes',
            field: 'internalProductNotes',
            type: 'text',
            className: "show-all-text"
        },
        {
            title: 'Class',
            field: 'productClass',
            type: 'text',
            width: 50,
            className: "show-all-text"
        },
        {
            title: 'NMFC',
            field: 'nmfcCode',
            type: 'text',
            width: 130,
            className: "show-all-text"
        },
    ];


    return (
        <TgfDialog title={"Select A Product"}
                   onClose={props.onClose}
                   width={"80vw"}>
            <Grid container spacing={2} alignItems={"flex-end"}>
                <Grid item>
                    <Grid item xs={12}>
                        <TgfDataGrid
                            ref={ref}
                            onLoadDataPage={loadProducts}
                            gridColumns={gridColumns}
                            sort={[{field: "productDescription", dir: "asc"}]}
                            pageable={{
                                pageSize: 20,
                                buttonCount: 10,
                                pageSizes: [5, 10, 20, 50, 100, 500]
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </TgfDialog>
    );

};

