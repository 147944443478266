import {ComboBox} from "@progress/kendo-react-dropdowns";
import {Label} from "@progress/kendo-react-labels";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

const TgfAgencyAssociatesComboBox = (props) => {
    const {
        id,
        label,
        selectedAgencyAssociate,
        onChange,
        activeOnly = false,
        visibilityPermissionIdFilter = null,
        actionRoleId = null,
        agencyId= null,
    } = props;
    const personalDispatch = useDispatch();
    const [agencyAssociateData, setAgencyAssociateData] = useState([]);

    const loadAgencyAssociates = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadAgencyAssociate'));

            const odata = {
                top: 100,
                skip: 0,
            };

            if (visibilityPermissionIdFilter) {
                const agencyAccessFilters = await window.shell.gateway.getAgencyAccessFilters();
                const agencyAccessId = agencyAccessFilters.find(
                    (item) => item.id === visibilityPermissionIdFilter,
                )?.id;
                odata.filter = {accessFilterId: agencyAccessId};
            }

            if (activeOnly) {
                odata.filter.isActive = activeOnly;
            }

            if (actionRoleId) {
                odata.filter.actionRoleId = actionRoleId;
            }

            odata.filter.agencyId = agencyId;

            const {items} = await window.shell.gateway.getPageOfAgencyAssociates(odata);

            items.forEach((associate) => {
                associate.fullName = `${associate.firstName} ${associate.lastName}`;
            });
            setAgencyAssociateData(items);

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadAgencyAssociate'));
        }
    };

    useEffect(() => {
        loadAgencyAssociates();
    }, []);

    return (
        <div>
            {label && <Label editorId={id}>{label}</Label>}
            <ComboBox
                {...props}
                className={"tgf-kendo-combobox"}
                data={agencyAssociateData}
                dataItemKey={"id"}
                textField="fullName"
                filterable={true}
                onChange={onChange}
                value={selectedAgencyAssociate}
                placeholder="Search Agency Associates..."
                label={null}
            />
        </div>
    );
};

export default TgfAgencyAssociatesComboBox;
