import {TimePicker} from "@progress/kendo-react-dateinputs";
import React from "react";

const TgfTimeInput = (props) => {

    const handleChange = (e) => {
        props.onChange(e);
    };

    return (
        <TimePicker
            {...props}
            disabled={props.disabled || false}
            format={props.format || "hh:mm a"}
            defaultValue={props.value ?? null}
            onChange={handleChange}
        />
    );
};

export default TgfTimeInput;