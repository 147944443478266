import AppMuiCard from "../../../../../core/components/cards/AppCard";
import {Grid} from "@material-ui/core";
import React from "react";
import DateUtils from "../../../../../core/utils/dateUtils/dateUtils";
import {Button} from "@progress/kendo-react-buttons";
import InfoIconTooltip from "../../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";


export const ShipmentSummary = (props) => {

    const {
        ltlShipmentDetails,
        additionalData,
    } = props?.data;

    const siteTypes = additionalData?.siteTypes.filter(x => [1,2].includes(x.id));
    const nonCommercialSiteTypes = additionalData?.siteTypes.filter(x => !([1,2].includes(x.id)));

    const originAccessorialOptions = [
        {id: 1, display: "Lift Gate"},
        {id: 2, display: "Inside Pickup"},
        {id: 3, display: "Appointment"},
    ];

    const destinationAccessorialOptions = [
        {id: 1, display: "Lift Gate"},
        {id: 2, display: "Notify Prior To Arrival"},
        {id: 3, display: "Inside Delivery"},
        {id: 4, display: "Appointment"},
        {id: 5, display: "Sort & Segregate"},
    ];

    const renderOriginAccesorials = () => {
        const originAccessorialIds = [];

        if(ltlShipmentDetails?.origin?.isLiftGatePickup) {
            originAccessorialIds.push(1);
        }
        if(ltlShipmentDetails?.origin?.isInsidePickup) {
            originAccessorialIds.push(2);
        }
        if(ltlShipmentDetails?.origin?.byAppointmentOnly) {
            originAccessorialIds.push(3);
        }
        return originAccessorialIds.map((id, idx) => {
            return <span
                key={idx}>{originAccessorialOptions.find(option => option.id === id)?.display}, </span>;
        });
    };

    const renderDestinationAccesorials = () => {
        const destinationAccessorialIds = [];

        if(ltlShipmentDetails?.destination?.isLiftGateDelivery) {
            destinationAccessorialIds.push(1);
        }
        if(ltlShipmentDetails?.destination?.notifyPriorToArrival) {
            destinationAccessorialIds.push(2);
        }
        if(ltlShipmentDetails?.destination?.isInsideDelivery) {
            destinationAccessorialIds.push(3);
        }
        if(ltlShipmentDetails?.destination?.byAppointmentOnly) {
            destinationAccessorialIds.push(4);
        }
        if(ltlShipmentDetails?.destination?.sortAndSegregate) {
            destinationAccessorialIds.push(5);
        }
        return destinationAccessorialIds.map((id, idx) => {
            return <span
                key={idx}>{destinationAccessorialOptions.find(option => option.id === id)?.display}, </span>;
        });
    }


    const formattedValues = {
        pickupDate: ltlShipmentDetails?.pickupDateOnly ?
                DateUtils.getDisplayDateFromDateString(ltlShipmentDetails?.pickupDateOnly)
            : null,
        originZipPostal: ltlShipmentDetails?.origin?.postalCode ?? ltlShipmentDetails?.originTerminal?.postalCode,
        originLocationType: ltlShipmentDetails?.origin?.siteTypeId ?
            siteTypes.find(x => x.id === ltlShipmentDetails?.origin?.siteTypeId)?.display
            : null,
        pickupSite: ltlShipmentDetails?.origin?.siteTypeId ?
            nonCommercialSiteTypes.find(x => x.id === ltlShipmentDetails?.origin?.siteTypeId)?.display
            : null,
        originAccessorials: renderOriginAccesorials(),
        destinationZipPostal: ltlShipmentDetails?.destination?.postalCode ?? ltlShipmentDetails?.destinationTerminal?.postalCode,
        destinationLocationType: ltlShipmentDetails?.destination?.siteTypeId ?
            siteTypes.find(x => x.id === ltlShipmentDetails?.origin?.siteTypeId)?.display
            : null,
        deliverySite: ltlShipmentDetails?.destination?.siteTypeId ?
            nonCommercialSiteTypes.find(x => x.id === ltlShipmentDetails?.destination?.siteTypeId)?.display
            : null,
        destinationAccessorials: renderDestinationAccesorials(),
        totalWeight: ltlShipmentDetails?.shipmentItems.reduce((accumulator, currentObject) => {
            return accumulator + currentObject.weightLbs;
        }, 0),
        isHasMat: ltlShipmentDetails?.shipmentItems.find(x => x?.isHazMat === true) ? "Yes" : "No",
        selectedCarrier: ltlShipmentDetails?.carrierName,
        guaranteedVerbiage: ltlShipmentDetails?.guaranteeVerbiage,
        isGuaranteed: ltlShipmentDetails?.isGuaranteedRate ? "Yes" : "no"
    };
    
    return (
        <Grid item xs={12} md={12} lg={12}>
            <AppMuiCard title={<div className={"card-title"}><span>Shipment Summary</span> <InfoIconTooltip title={"TBD - Mike will decide"} /></div>}>
                <Grid container className={"quote-summary-card"}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <span>Pickup Date: </span>
                        <span className={"highlighted"}>{formattedValues?.pickupDate}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2}>
                        <span>P/U Zip/Postal: </span>
                        <span className={"highlighted"}>{formattedValues?.originZipPostal}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2}>
                        <span>P/U Location Type: </span>
                        <span className={"highlighted"}>{formattedValues?.originLocationType}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={3}>
                        <span>Non Commercial P/U Site: </span>
                        <span className={"highlighted"}>{formattedValues?.pickupSite}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={5}>
                        <span>P/U Accessorials: </span>
                        <span className={"highlighted"}>{formattedValues?.originAccessorials}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2}>
                        <span>Del. Zip/Postal: </span>
                        <span className={"highlighted"}>{formattedValues?.destinationZipPostal}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2}>
                        <span>Del. Location Type: </span>
                        <span className={"highlighted"}>{formattedValues?.destinationLocationType}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={3}>
                        <span>Non Commercial Del. Site: </span>
                        <span className={"highlighted"}>{formattedValues?.deliverySite}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={5}>
                        <span>Del. Accessorials: </span>
                        <span className={"highlighted"}>{formattedValues?.destinationAccessorials}</span>
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={2}>
                        <span>Total Weight:  </span>
                        <span className={"highlighted"}>{formattedValues?.totalWeight} lbs</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={2}>
                        <Button
                            onClick={() => props.setShipmentContentsOpen(true)}
                            fillMode={"flat"}
                            className={"quote-summary__view-contents-button"}
                        >
                            View Contents
                        </Button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <span>Haz-Mat  </span>
                        <span className={"highlighted"}>{formattedValues?.isHasMat}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={4}>
                        <span>Guaranteed Service:  </span>
                        <span className={"highlighted"}>{formattedValues?.isGuaranteed}</span>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={3}>
                        <span>Selected Carrier:  </span>
                        <span className={"highlighted"}>{formattedValues?.selectedCarrier}</span>
                    </Grid>
                    {formattedValues.guaranteedVerbiage &&
                        <Grid item xs={6} sm={6} md={6} lg={3}>
                            <span>Guaranteed Verbiage:  </span>
                            <span className={"highlighted"}>{formattedValues?.guaranteedVerbiage}</span>
                        </Grid>
                    }
                </Grid>
            </AppMuiCard>
        </Grid>
    );
};