import React from 'react';
import Core from '@atomos/core';
import {makeStyles} from '@material-ui/core/styles';
import ComponentBuilder from '../../core/ComponentBuilder';
import {Autocomplete} from '@material-ui/lab';
import AppTooltip from "../../core/components/AppTooltip";
import When from "../../core/components/condtionals/When";
import AppTextBox from '../../core/components/inputs/AppTextBox/AppTextBox';
import combineClassNames from '../../core/utils/combineClassNames';

// TODO: change AutoComplete to new improved AppAutoComplete
// import AppAutoComplete from '../../core/components/dropdowns/AppAutoComplete';

const constructTooltipTitle = (company) => {
  if (company) {
    return (
      <>
        <div>
          <strong>{company.companyName}</strong>
        </div>
        < Address
          address1={company.companyAddress1}
          address2={company.companyAddress2}
          city={company.companyCity}
          stateProvince={company.companyStateProvince}
          postalCode={company.companyPostalCode}
          businessPhone={company.companyBusinessPhone}
          primaryContactFirstName={company.primaryContactFirstName}
          primaryContactLastName={company.primaryContactLastName}
        />
      </>
    )
  }

  return null;
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.white,
    padding: 0,
    '& .MuiOutlinedInput-root': {
      borderColor: theme.palette.tgfGrey.border,
      padding: '0 0 0 10px'
    }
  },
  disabled: {
    backgroundColor: theme.palette.tgfGrey.disabled
  }
}))

const CrmAddressBookListingComboBox = (props) => {
  const classes = useStyles();

  const {
    searchAddressBookListing,
    loadIndividualCompany,
    filterMap = Core.Utils.identity,
    onChange,
    placeholder,
    value,
    disabled = false,
    ...otherProps
  } = props;

  const safePropPairs = Core.Utils.toPairs(otherProps)
    .filter(([key]) => key !== 'staticContext');
  const safeProps = Core.Utils.fromPairs(safePropPairs);
  const [isOpen, setIsOpen] = React.useState(false);
  const [addressBookListings, setAddressBookListings] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [tooltipTitle, setTooltipTitle] = React.useState('');
  const [tooltipValue, setTooltipValue] = React.useState(null);

  React.useEffect(() => {
    setAddressBookListings([]);
  }, [value]);

  React.useEffect(() => {
    if((value && !tooltipValue) || (value && tooltipValue && value.id !== tooltipValue.id)) {
      setTooltipValue(value);
    } else {
        setTooltipValue(null);
    }
  }, [value]);
  React.useEffect(() => {
    if(tooltipValue) {
      loadIndividualCompany(tooltipValue).then((company) => {
        setTooltipTitle(constructTooltipTitle(company));
      });
    } else {
        setTooltipTitle(null);
    }
  }, [tooltipValue]);

  const handleSearchTermChange = async (e) => {
    setIsLoading(true);

    const searchTerm = e.target.value;
    let companies = [];

    if (searchTerm.trim().length > 0) {

      const filter = filterMap({
        companyName: {
          $like: `${searchTerm}%`
        }
      });

      if (filter) {
        companies = await searchAddressBookListing(filter);
      }

    }

    setAddressBookListings(companies);
    setIsLoading(false);
  };

  const handleOnChange = (e, abListing, reason) => {
    setTooltipTitle(constructTooltipTitle(abListing));
    onChange(abListing);
  };

  return (
    <Autocomplete
      className={combineClassNames(classes.root, disabled ? classes.disabled : null)}
      open={isOpen}
      disabled={disabled}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      options={addressBookListings}
      loading={isLoading}
      value={value}
      onChange={handleOnChange}
      {...safeProps}
      getOptionSelected={(abListing, selectedAbListing) => abListing.companyId === selectedAbListing.companyId}
      getOptionLabel={(abListing) => abListing.companyName}
      renderInput={
        (params) => {
          return (
            <AppTooltip title={tooltipTitle}>
              <AppTextBox
                {...params}
                variant={'outlined'}
                onChange={handleSearchTermChange}
                placeholder={placeholder}
                inputProps={{
                  ...params.inputProps,
                    ...props?.additionalInputProps
                }}
              />
            </AppTooltip>
          )
        }
      }
      renderOption={
        (abListing, state) => {
          return (
            <div>
              <strong style={{ display: 'block' }}>{abListing.companyName} ({abListing.categoryTypeName})</strong>
              <div>{abListing.companyAddress1}</div>
              { abListing.companyAddress2 && <div>{abListing.companyAddress2}</div>}
              <div>{abListing.companyCity}, {abListing.companyStateProvince}, {abListing.companyPostalCode}</div>
            </div>
          );
        }
      }
    />
  );
};

const Address = (props) => {

  const {
    address1,
    address2 = '',
    city,
    stateProvince,
    postalCode,
    businessPhone,
    primaryContactFirstName,
    primaryContactLastName
  } = props;

  const hasAddress = Boolean(
    address1 &&
    city &&
    stateProvince &&
    postalCode
  );

  if (!hasAddress)
    return null;

  return (
    <React.Fragment>
      <div>
        {address1}
      </div>
      <When condition={address2}>
        {() =>
          <div>
            {address2}
          </div>
        }
      </When>
      <div>
        {city}, {stateProvince} {postalCode}
      </div>
      {
        (primaryContactFirstName || primaryContactLastName) &&
        <div>
          {primaryContactFirstName} {primaryContactLastName}
        </div>
      }
      <div>
        {businessPhone}
      </div>
    </React.Fragment>
  );
};


/*

1. Wire to redux store to fetch companies.
2. Create default renderer for Company (should show address); allow override.
3. Create event handler for propagating selected company.


 */

export default ComponentBuilder
  .wrap(CrmAddressBookListingComboBox)
  .dispatchToProps((shell, dispatch, ownProps) => {
    return {
      async searchAddressBookListing(filter = {}) {
        const searchOptions = {
          filter,
          limit: ownProps.limit
        };
        const { companies } = await shell.gateway.searchCompanies(searchOptions);
        return companies;
      },
      async loadIndividualCompany(value) {
        const searchOptions = {
          filter: {
            companyId: value.companyId
          },
          limit: 1
        };
        const results = await shell.gateway.searchCompanies(searchOptions);
        return results.companies[0];
      }
    };
  })
  .build();