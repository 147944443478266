import TgfTextArea from "../../../../../../core/kendo/form/inputs/TgfTextArea";
import AppMuiCard from "../../../../../../core/components/cards/AppCard";
import {Grid} from "@material-ui/core";
import {Label} from "@progress/kendo-react-labels";
import InfoIconTooltip from "../../../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";
import React, {useEffect} from "react";

export const GeneralBolNotesCard = (props) => {

    const {
        formValues,
        applyFormValue,
        formData,
        disableAll = false
    } = props;

    const handleChange = (e) => {
        applyFormValue(e.target.name, e.target.value);
    };

    const createNewBolNote = () => {
        let generalBolNoteDraft = '';
        // Guaranteed Transit Verbiage
        if (formValues.selectedQuote.guaranteedRate) {
            generalBolNoteDraft += `***${formValues.selectedQuote.guaranteedRate?.guarantee}***. `
        }

        // Protect from freeze toggle
        if (formValues.protectFromFreeze) {
            generalBolNoteDraft += (`Protect From Freeze. `);
        }

        // Residence as the Origin's Location Type
        if (formValues.originLocationType) {
            const id = formData.originLocationTypes.find(ot => ot.id === formValues.originLocationType)?.id;

            if (id && id === 2) {
                generalBolNoteDraft += `Residential Pickup. `;
            }
        }

        // Origin's non-commercial pickup site options
        if (formValues.pickupSite) {
            const pickupSiteDisplay = formData.pickupSiteOptions.find(ps => ps.id === formValues.pickupSite)?.display;

            if (pickupSiteDisplay) {
                generalBolNoteDraft += pickupSiteDisplay + ` Pickup. `;
            }
        }

        // Origin's accessorials
        if (formValues.originAccessorials) {
            let originAccessorialDisplay = '';
            formValues.originAccessorials.forEach((accessorial) => {
                const display = formData.originAccessorialOptions.find(oa => oa.id === accessorial)?.display;
                if (display) {
                    let tempOriginAccessorialDisplay = '';
                    switch (display) {
                        case 'Lift Gate':
                            tempOriginAccessorialDisplay = display + `${' '}Pickup.${' '}`;
                            break;
                        case 'Inside Pickup':
                            tempOriginAccessorialDisplay = display + `.${' '}`;
                            break;
                        case 'Appointment':
                            tempOriginAccessorialDisplay = `Pickup ` + display + `.${' '}`;
                            break;
                    }
                    originAccessorialDisplay += tempOriginAccessorialDisplay;
                }
            });
            generalBolNoteDraft += originAccessorialDisplay;
        }

        // "Residence" as the destination's Location Type
        // "Tradeshow" as the Destination's Location Type
        if (formValues.destinationLocationType) {
            const display = formData.destinationLocationTypes.find(dl => dl.id === formValues.destinationLocationType)?.display;

            if (display) {
                let destinationLocationDisplay = '';
                switch (display) {
                    case 'Residential':
                        destinationLocationDisplay = `Residential Delivery.${' '}`;
                        break;
                    case 'Tradeshow':
                        destinationLocationDisplay = display + `${' '}Delivery.${' '}`;
                        break;
                }
                generalBolNoteDraft += destinationLocationDisplay;
            }
        }

        // Destination's Non-Commercial Delivery
        if (formValues.deliverySite) {
            const deliverySiteDisplay = formData.deliverySiteOptions.find(ds => ds.id === formValues.deliverySite)?.display;
            generalBolNoteDraft += deliverySiteDisplay + " Delivery. ";
        }

        // All of Destination's Accessorials
        if (formValues.destinationAccessorials) {
            let destinationAccessorialsDisplay = '';
            formValues.destinationAccessorials.forEach((accessorial) => {
                const accessorialObject = formData.destinationAccessorialOptions.find(da => da.id === accessorial);
                let tempDestinationAccessorialDisplay = '';
                if (accessorialObject) {
                    switch (accessorialObject?.id) {
                        case 1:
                            tempDestinationAccessorialDisplay = accessorialObject.display + `${' '}Delivery.${' '}`;
                            break;
                        case 2:
                            tempDestinationAccessorialDisplay = `Notify Prior to Delivery.${' '}`;
                            break;
                        case 4:
                            tempDestinationAccessorialDisplay = `Delivery${' '}` + accessorialObject.display + `.${' '}`;
                            break;
                        default:
                            tempDestinationAccessorialDisplay = accessorialObject.display + `.${' '}`;
                            break;
                    }
                    destinationAccessorialsDisplay += tempDestinationAccessorialDisplay;
                }
            });
            generalBolNoteDraft += destinationAccessorialsDisplay;
        }

        return generalBolNoteDraft;
    };

    const generateBolNotes = async () => {
        if (!disableAll) {
            const newGeneralBolNote = createNewBolNote();
            applyFormValue('generalBolNotes', newGeneralBolNote);
        }
    };

    useEffect(() => {
        if (formData) {
            generateBolNotes();
        }
    }, [formData]);

    return (
        <AppMuiCard title="General Notes / BOL Notes">
            <Grid container alignItems={"center"} spacing={2}>
                <Grid item xs={12}>
                    <Label>
                        General Notes / BOL Notes (850 max characters)
                    </Label>
                    {/* TODO check the title for this tooltip */}
                    <InfoIconTooltip
                        title={`TBD. Clemmons will provide them later.`}
                    />
                    <TgfTextArea
                        id={"generalBolNotes"}
                        name={"generalBolNotes"}
                        maxLength={850}
                        rows={8}
                        value={formValues.generalBolNotes}
                        onChange={handleChange}
                        disabled={disableAll}
                    />
                </Grid>

            </Grid>
        </AppMuiCard>
    );
};