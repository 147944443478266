import {Grid, Typography} from "@material-ui/core";
import _ from "lodash";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import useIsAdministrator from "../../../hubs/personaV2/selectors/useIsAdministrator";
import {useStyles} from "@material-ui/pickers/views/Year/Year";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
import AddressBookRecordNav from "../AddressBookRecordNav";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import CheckIfAuthorized from "../tempSecurity/CheckIfAuthorized";
import MissingOrDeniedCompanyAlert from "../MissingOrDeniedCompanyAlert";
import AppMuiButton from "../../../core/components/AppButton";
import {Save, Undo} from "@material-ui/icons";
import TgfAgencyAssociatesComboBox from "../../../core/kendo/form/inputs/specialty/TgfAgencyAssociatesComboBox";
import TgfMultiList from "../../../core/kendo/multiple-list/TgfMultiList";

const AssignmentPage = () => {
    const classes = useStyles();
    const urlParams = useParams();
    const companyId = parseInt(urlParams?.id);
    const isAdmin = useIsAdministrator();
    const modifyingAssociate = useSelector(state => state.persona.modifyingAssociate);
    const personalDispatch = useDispatch();
    const ref = useRef();

    const [isReady, setIsReady] = useState(false);
    const [company, setCompany] = useState(null);
    const [agencyId, setAgencyId] = useState([]);

    const [availableAssociates, setAvailableAssociates] = useState([]);
    const [involvedAssociates, setInvolvedAssociates] = useState([]);

    const [initialAvailableAssociates, setInitialAvailableAssociates] = useState([]);
    const [initialInvolvedAssociates, setInitialInvolvedAssociates] = useState([]);


    usePageTitle(company ? `Add: ${company?.name.substring(0, 20)}` : null);

    const [selectedAgencyAssociate, setSelectedAgencyAssociate] = useState();
    const [initialSelectedAgencyAssociate, setInitialSelectedAgencyAssociate] = useState();

    const [isDirty, setIsDirty] = useState(false);


    function not(a, b) {
        return a.filter((value) => b.indexOf(value) === -1);
    }

    function intersection(a, b) {
        return a.filter((value) => b.indexOf(value) !== -1);
    }

    const checkIfDirty = () => {
        if (isReady) {
            setIsDirty(false);

            if (selectedAgencyAssociate != null && (initialSelectedAgencyAssociate == null || selectedAgencyAssociate.id != initialSelectedAgencyAssociate.id))
                setIsDirty(true);

            if (!(involvedAssociates.every(item => initialInvolvedAssociates.includes(item)) && initialInvolvedAssociates.every(item => involvedAssociates.includes(item))))
                setIsDirty(true);
        }
    }

    const handleSelectionOfAgencyAssociate = (e) => {
        setSelectedAgencyAssociate(e.value);
    };

    const loadPageDependencies = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadAgencyAssociate'));

            const company = await window.shell.gateway.getCompany(companyId);
            setCompany(company);
            setAgencyId(company.associateId);

            const agencyAssociates = (await window.shell.gateway.getPageOfAgencyAssociates({
                filter: {
                    agencyId: company.associateId,
                    accessFilterId: 2,
                    isActive: true
                }
            })).items;

            const agencyInvolvedAssociates = await window.shell.gateway.retrieveInvolvedUsers(companyId);
            const involvedAssociates = [];

            agencyInvolvedAssociates.forEach((item) =>{
                const user = agencyAssociates.filter((obj) => obj.id === item.associateId)[0];
                const index = agencyAssociates.indexOf(user);
                let actual = agencyAssociates[index];
                if (actual == null) return;
                actual.fullName = `${actual.firstName} ${actual.lastName}`;
                involvedAssociates.push(actual);
            });

            const customerOwner = agencyInvolvedAssociates.find((associate) => associate.isOwner);
            if (customerOwner) {
                const selectedAgencyAssociate = involvedAssociates.find((associate) => associate.id == customerOwner.associateId);
                setSelectedAgencyAssociate(selectedAgencyAssociate);
                setInitialSelectedAgencyAssociate(selectedAgencyAssociate);
            }

            setInvolvedAssociates(involvedAssociates);
            setInitialInvolvedAssociates(involvedAssociates);

            setAvailableAssociates(agencyAssociates);
            setInitialAvailableAssociates(agencyAssociates);

            setIsReady(true);

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadAgencyAssociate'));
        }
    };

    const save = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('saveAgencyAssociate'));

            await window.shell.gateway.setInvolvedUsers(companyId, agencyId, selectedAgencyAssociate.id, involvedAssociates);

            await loadPageDependencies();
        } catch (e) {
            console.log(e);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Failed To Save User Assignments',
                color: 'error',
            }));
        } finally {
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'User Assignments Saved',
            }));
            personalDispatch(window.shell.actions.sys.processComplete('saveAgencyAssociate'));
        }
    };

    const handleResetClick = () => {
        setSelectedAgencyAssociate(initialSelectedAgencyAssociate);
        setAvailableAssociates(initialAvailableAssociates);
        setInvolvedAssociates(initialInvolvedAssociates);
        ref.current.reset(initialInvolvedAssociates, initialAvailableAssociates);
        setIsDirty(false);
    };

    const handleListChange = (value) => {
        setAvailableAssociates(value.right);
        setInvolvedAssociates(value.left);
    };

    useEffect(() => {
        loadPageDependencies();
    }, []);

    useEffect(() => {
        checkIfDirty();
    }, [selectedAgencyAssociate, involvedAssociates]);

    return (
        <FullWidthLayout
            SideNav={AddressBookRecordNav} title={`Address Book - Assignment - ${company?.name || ""}`}
            className={`${classes.header} address-book-styles`}
        >
            {company?.name ?
                <CheckIfAuthorized
                    isAdmin={isAdmin}
                    modifyingAssociate={modifyingAssociate}
                    company={company}
                >
                    <Grid container spacing={3}>
                        {/* Buttons */}
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <AppMuiButton
                                        type={'submit'}
                                        startIcon={<Save/>}
                                        disabled={!isDirty}
                                        onClick={save}>
                                        Save
                                    </AppMuiButton>
                                </Grid>
                                <Grid item>
                                    <AppMuiButton
                                        type={'button'}
                                        startIcon={<Undo/>}
                                        color={'warning'}
                                        disabled={!isDirty}
                                        onClick={handleResetClick}>
                                        Reset
                                    </AppMuiButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h3">Customer Owner Assignment</Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                {isReady && <TgfAgencyAssociatesComboBox
                                    label={"Customer Owner:"}
                                    onChange={handleSelectionOfAgencyAssociate}
                                    selectedAgencyAssociate={selectedAgencyAssociate}
                                    activeOnly={true}
                                    visibilityPermissionIdFilter={2}
                                    actionRoleId={3}
                                    agencyId={agencyId}
                                />}
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2}>
                            {isReady && <TgfMultiList
                                ref={ref}
                                right={availableAssociates}
                                left={involvedAssociates}
                                rightLabel={'Available Users to Assign...'}
                                leftLabel={'Assigned Users...'}
                                onChange={handleListChange}
                                idFunction={(item) => `${item.firstName} ${item.lastName}`}
                                displayFunction={(item) => `${item.firstName} ${item.lastName} - ${item.initials}`}
                            />}
                        </Grid>
                    </Grid>
                </CheckIfAuthorized>
                :
                <MissingOrDeniedCompanyAlert/>
            }
        </FullWidthLayout>
    );
};

export default AssignmentPage;
