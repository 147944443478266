import AppMuiCard from "../../../../../core/components/cards/AppCard";
import React from "react";
import {Grid} from "@material-ui/core";
import InfoIconTooltip from "../../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";


export const ConsigneeInfo = (props) => {

    const {
        ltlShipmentDetails
    } = props.data;

    return (
        <AppMuiCard title={<div className={"card-title"}><span>Consignee</span> <InfoIconTooltip title={"TBD - Mike will decide"} /></div>}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    Name: <strong><span style={{color: "red"}}>{ltlShipmentDetails?.destination?.businessName}</span></strong>
                </Grid>
                <Grid item xs={12}>
                    Address 1: <strong>{ltlShipmentDetails?.destination?.address1}</strong>
                </Grid>
                <Grid item xs={12}>
                    Address 2: <strong>{ltlShipmentDetails?.destination?.address2}</strong>
                </Grid>
                <Grid item xs={5}>
                    City: <strong>{ltlShipmentDetails?.destination?.city}</strong>
                </Grid>
                <Grid item xs={2}>
                    State: <strong>{ltlShipmentDetails?.destination?.state}</strong>
                </Grid>
                <Grid item xs={5}>
                    Zip/Postal: <strong>{ltlShipmentDetails?.destination?.postalCode}</strong>
                </Grid>
                <Grid item xs={12}>
                    Contact Name: <strong>{ltlShipmentDetails?.destination?.primaryContactName}</strong>
                </Grid>
                <Grid item xs={12}>
                    Contact Phone: <strong>{ltlShipmentDetails?.destination?.primaryContactPhone}</strong>
                </Grid>
            </Grid>
        </AppMuiCard>
    )
}
