import {ComboBox} from "@progress/kendo-react-dropdowns";
import {Label} from "@progress/kendo-react-labels";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";

const TgfAgencyComboBox = (props) => {
    const {
        id,
        label,
        selectedAgency,
        onChange,
        franchiseMode = null,
        isDisabled = null,
    } = props;
    const personalDispatch = useDispatch();
    const [agencyData, setAgencyData] = useState([]);

    const loadAllAgencies = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('getAgencies'));

            let agencies = await window.shell.gateway.getAllAgencies(franchiseMode, isDisabled);
            setAgencyData(agencies);

        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('getAgencies'));
        }
    };

    useEffect(() => {
        loadAllAgencies();
    }, []);

    return (
        <div>
            {label && <Label editorId={id}>{label}</Label>}
            <ComboBox
                {...props}
                className={"tgf-kendo-combobox"}
                data={agencyData}
                dataItemKey={"id"}
                textField="display"
                filterable={true}
                onChange={onChange}
                value={selectedAgency}
                placeholder="Search Agencies..."
                label={null}
            />
        </div>
    );
};

export default TgfAgencyComboBox;
