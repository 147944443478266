import React, {useEffect, useState} from "react";
import ShipmentRecordNav from "../ShipmentRecordNav";
import {ShipmentTitle} from "../ShipmentTitle";
import FullWidthLayout from "../../../core/layouts/FullWidthLayout";
import {useParams} from "react-router-dom";
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
import {Grid} from "@material-ui/core";
import {ShipmentSummary} from "./includes/ShipmentSummary/ShipmentSummary";
import {RefNumberInfo} from "./includes/RefNumberInfo/RefNumberInfo";
import {ShipperInfo} from "./includes/ShipperInfo/ShipperInfo";
import {ConsigneeInfo} from "./includes/ConsigneeInfo/ConsigneeInfo";
import {AvailableDocs} from "./includes/AvailableDocs/AvailableDocs";
import {OriginTerminal} from "./includes/OriginTerminal/OriginTerminal";
import {DestinationTerminal} from "./includes/DestinationTerminal/DestinationTerminal";
import {TrackingHistory} from "./includes/TrackingHistory/TrackingHistory";
import {useDispatch} from "react-redux";
import "./includes/LtlAutotrack.scss";
import TgfDialog from "../../../core/kendo/dialog/TgfDialog";
import ShipmentContentsListing from "./includes/ShipmentContentsListing/ShipmentContentsListing";


const LTLAutotrack = () => {
    const personalDispatch = useDispatch();
    const urlParams = useParams();
    const bolNumber = parseInt(urlParams?.id);
    const [data, setData] = useState({});
    const [shipmentContentsOpen, setShipmentContentsOpen] = useState(false);

    usePageTitle(`Ship ${bolNumber}`);

    const loadPageDeps = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadTrackingInformation'));
            const data = await window.shell.gateway.getLtlTracking(bolNumber);

            setData(data)
        } catch (e) {
            console.log(e);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Failed To Load Tracking Information',
                color: 'error',
            }));
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadTrackingInformation'));
        }
    };

    useEffect(() => {
        loadPageDeps(bolNumber);
    }, []);

    return (
        <FullWidthLayout SideNav={ShipmentRecordNav} title={<ShipmentTitle bolNumber={bolNumber} title={`Shipments - LTL Autotrack - ${bolNumber ? bolNumber : 'New'}`}/>} className={"shipments-styles"}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ShipmentSummary data={data} setShipmentContentsOpen={setShipmentContentsOpen} />
                </Grid>
                <Grid item xs={9}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <RefNumberInfo data={data} />
                        </Grid>
                        <Grid item xs={4}>
                            <ShipperInfo data={data} />
                        </Grid>
                        <Grid item xs={4}>
                            <ConsigneeInfo data={data} />
                        </Grid>
                        <Grid item xs={4}>
                            <AvailableDocs data={data}/>
                        </Grid>
                        <Grid item xs={4}>
                            <OriginTerminal data={data} />
                        </Grid>
                        <Grid item xs={4}>
                            <DestinationTerminal data={data} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <TrackingHistory data={data} />
                </Grid>
                {shipmentContentsOpen &&
                    <TgfDialog
                        onClose={() => setShipmentContentsOpen(false)}
                        width={"90%"}
                        title={"View Contents"}
                    >
                        <ShipmentContentsListing data={data}/>
                    </TgfDialog>
                }
            </Grid>
        </FullWidthLayout>
    )
}

export default LTLAutotrack;