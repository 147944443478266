import AppMuiCard from "../../../../../../../core/components/cards/AppCard";
import {Grid} from "@material-ui/core";
import {QuoteResult} from "./includes/QuoteResult";
import React from "react";
import {TgfButtonGroupInput} from "../../../../../../../core/kendo/form/inputs/TgfButtonGroupInput";
import "./QuoteResults.scss";

export const QuoteResults = (props) => {

    const {
        quoteResults,
        formValues,
        applyFormValue,
        filter,
        handleFilterChange,
        filterOptions,
        stepForward,
        type,
    } = props;

    return (
        <Grid item xs={12} md={12} lg={12}>
            <AppMuiCard title={'Quote Results'}>
                <Grid container spacing={2} className={"quote-results-card"}>
                    <Grid item xs={12} md={12} lg={6}>
                        <TgfButtonGroupInput
                            id={"quoteResultsFilter"}
                            name={"quoteResultsFilter"}
                            value={filter}
                            data={filterOptions}
                            isMultiSelect={false}
                            onChange={handleFilterChange}
                        />
                    </Grid>
                    {quoteResults.length > 0 ?
                        quoteResults.map((result, idx) => {
                            return (
                                <Grid item xs={12}>
                                    <QuoteResult
                                        data={result}
                                        key={idx}
                                        formValues={formValues}
                                        applyFormValue={applyFormValue}
                                        stepForward={stepForward}
                                        type={type}
                                    />
                                </Grid>
                            );
                        })
                        :
                        <Grid item xs={12}>
                            <p style={{textAlign: "center", fontWeight: "bold"}}>No Results...</p>
                        </Grid>
                    }
                </Grid>
            </AppMuiCard>
        </Grid>

    );
};