import AppMuiCard from "../../../../../core/components/cards/AppCard";
import React from "react";
import InfoIconTooltip from "../../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";


export const AvailableDocs = (props) => {
    const {
        ltlTracking,
    } = props.data;
    return (
        <AppMuiCard title={<div className={"card-title"}><span>Available Documents</span> <InfoIconTooltip title={"TBD - Mike will decide"} /></div>}>
            {/*  TODO, map out link data when we have some available?  */}
            <span>No Documents available</span>
        </AppMuiCard>
    )
}
