import React, {forwardRef, useEffect, useState} from 'react';
import {
    ArtTrack,
    Assessment,
    AssessmentOutlined,
    Assignment,
    LibraryAddCheck,
    ListAlt,
    LocalShipping,
    Notes,
} from '@material-ui/icons';


import AppSideBar from '../../core/components/AppSideBar';
import useIsAdministrator from "../../hubs/personaV2/selectors/useIsAdministrator";
import FreightCategoryTypeNames from '../../hubs/shipment/FreightCategoryTypeNames';
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {MdMediation} from "react-icons/md";

const ShipmentUrl = '/shipment/';

const BaseLinks = (shipmentNumber) => [
  { title: 'Details', key: 'details', icon: ArtTrack, href: `${ShipmentUrl}${shipmentNumber}/details` },
];

const AdditionalLinks = (shipmentNumber) => [
  { title: 'Load Contents', key: 'contents', icon: Assignment, href: `${ShipmentUrl}${shipmentNumber}/contents` },
  { title: 'Tracking Notes', key: 'load-tracking', icon: Notes, href: `${ShipmentUrl}${shipmentNumber}/load-tracking` },
  { title: 'Documents', key: 'documents', icon: ListAlt, href: `${ShipmentUrl}${shipmentNumber}/documents` },
  { title: 'Financial', key: 'financial', icon: Assessment, href: `${ShipmentUrl}${shipmentNumber}/financial` },
];

const AuditLink = (shipmentNumber) => [
  { title: 'Auditing', key: 'auditing', icon: LibraryAddCheck, href: `${ShipmentUrl}${shipmentNumber}/auditing` }
];

const MultiStopLink = (shipmentNumber) => [
  { title: 'Multi-Stop', key: 'multiple-stops', icon: LocalShipping, href: `${ShipmentUrl}${shipmentNumber}/multiple-stops` }
];

const ltlAutotrackLink = (shipmentNumber) => [
    { title: 'LTL Autotrack', key: 'ltl-autotrack', icon: LocalShipping, href: `${ShipmentUrl}${shipmentNumber}/ltl-autotrack` }
];


const MultiTransLink = (shipmentNumber) => [
    { title: 'Multi-Transactions', key: 'multi-transactions', icon: forwardRef(() => <MdMediation size={24} />), href: `${ShipmentUrl}${shipmentNumber}/multi-transactions` }
];

const ShipmentRecordNav = (props) => {
    const personalDispatch = useDispatch();
    const state = useSelector(state => state);
    let { id } = useParams();
    const bolNumber = id;
    const [links, setLinks] = useState([]);
    const [open, setOpen] = useState(true);
    const canViewAccountingTab = useSelector(state => state).persona.agent.canViewAccountingTab;
    const isAdmin = useIsAdministrator();

    const loadDeps = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadShipment'));
            const shipment = await window.shell.gateway.getShipment(parseInt(bolNumber));

            const freightCategoryTypes = state.support.freightCategoryTypes;
            if (!shipment || !freightCategoryTypes) {
                setLinks([]);
            }

            const isNew = shipment.bolNumber === 0;
            const defaultFreightCategoryType = freightCategoryTypes.find(x => x.name === FreightCategoryTypeNames.Truckload);
            const isLoadTl = defaultFreightCategoryType && defaultFreightCategoryType.id === shipment.freightCategoryId;

            let links = [];

            if (isNew) {
                links = links.concat(BaseLinks('new'));
            }
            else {
                links = BaseLinks(shipment.bolNumber)
                    .concat(AdditionalLinks(shipment.bolNumber));
                if(canViewAccountingTab) links.push({ title: 'Accounting', key: 'accounting', icon: AssessmentOutlined, href: `${ShipmentUrl}${shipment.bolNumber}/accounting` });
            }

            if (isAdmin) {
                links = links.concat(AuditLink(shipment.bolNumber));
            }

            if (isLoadTl) {
                links = links.concat(MultiStopLink(shipment.bolNumber));
            }

            //links = links.concat(MultiTransLink(shipment.bolNumber));

            if(shipment.createdViaLtlRatingsEngine === true) {
                links = links.concat(ltlAutotrackLink(shipment.bolNumber))
            }

            setLinks(links);
        } catch (e) {

        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadShipment'));
        }

    };

    useEffect(() => {
        loadDeps();
    }, [])

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <AppSideBar menuList={links || []} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} open={open}/>
  );
};

export default ShipmentRecordNav;
