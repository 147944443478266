import React, {forwardRef, useState} from "react";
import {Grid} from "@material-ui/core";
import TgfDataGrid from "../../../../../core/kendo/dataGrid/TgfDataGrid";
import {
    loadFlagHazardousActiveIcon,
    loadFlagHazardousInactiveIcon,
} from "../../../../../core/media/icons/exportCustomIcons";
import TgfDialog from "../../../../../core/kendo/dialog/TgfDialog";
import {HazmatDetailsDialog} from "../HazmatDetailsDialog/HazmatDetailsDialog";
import {useDispatch} from "react-redux";

const ShipmentContentsListing = forwardRef((props, ref) => {
    const {
        ltlShipmentDetails,
        additionalData
    } = props.data

    const [hazmatDetails, setHazmatDetails] = useState(null);
    const personalDispatch = useDispatch();

    const loadLtlShipmentItems = async (odata) => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadLtlShipmentItems'));
            const result = await window.shell.gateway.getPageOfLtlShipmentItems(odata, ltlShipmentDetails.bolNumber);
            return result;
        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadLtlShipmentItems'));
        }
    }

    const renderDensityCell = (dataItem) => {
        const {
            packageCount = 0,
            weight = 0
        } = dataItem;
        let length, width, height;
        if (dataItem.dimensions) {
            [length, width, height] = dataItem.dimensions.split(' X ').map(Number);
        }
        let density = ((weight / ((length * width * height) / 1728)) / packageCount).toFixed(2);
        density = isFinite(parseFloat(density)) ? parseFloat(density).toLocaleString() : null;

        return (
            <td>
                <span>{density}</span>
            </td>
        )
    };

    const computeIcon = (dataItem, activeIcon, inactiveIcon, altMessage1, altMessage2) => {

        if(dataItem.isHazmat) {
            return <img
                className={"hazmat-icon"}
                src={activeIcon}
                alt={altMessage1}
                onClick={() => setHazmatDetails(dataItem)}
            />

        } else {
            return <img style={{height: 19, width: 19}} src={inactiveIcon} alt={altMessage2}/>
        }
    }

    const renderHazmatCell = (dataItem) => {
        return (
            <td style={{display: "flex", justifyContent: "center", alignItems: "center", padding:0}}><span style={{padding: 4}}>{computeIcon(dataItem, loadFlagHazardousActiveIcon, loadFlagHazardousInactiveIcon, "is hazardous", "is not hazardous",)}</span></td>
        )
    }

    const renderFreightClassCell = (dataItem) => {
        return (
            <td>{additionalData?.freightClasses.find(fc => fc.id === dataItem.freightClassId)?.display}</td>
        )
    }

    const gridColumns = [
        {
            title: 'Package Type',
            field: 'packageTypeName',
            type: 'text',
            width: 75
        },
        {
            title: '# of Units',
            field: 'packageCount',
            type: 'number',
            width: 65
        },
        {
            title: 'Description',
            field: 'description',
            type: 'text',
        },
        {
            title: 'NMFC',
            field: 'nmfc',
            type: 'number',
            width: 50

        },
        {
            title: 'Class',
            field: 'freightClassId',
            type: 'custom',
            cell: (props) => renderFreightClassCell(props.dataItem),
            width: 50
        },
        {
            title: 'Said to Contain',
            field: 'pieceCount',
            type: 'number',
            width: 75
        },
        {
            title: 'Weight (lbs)',
            field: 'weight',
            type: 'number',
            width: 80
        },
        {
            title: 'Dimensions (inches)',
            field: 'dimensions',
            type: "text",
            sortable: false,
            width: 80
        },
        {
            title: 'Density',
            field: '',
            type: 'custom',
            cell: (props) => renderDensityCell(props.dataItem),
            sortable: false,
            width: 75
        },
        {
            title: 'Stackable?',
            field: 'isStackable',
            type: 'custom',
            cell: (props) => <td><span>{props.dataItem.isStackable ? "Yes" : "No"}</span></td>,
            width: 70
        },
        {
            title: 'Hazmat',
            field: 'isHazmat',
            type: 'custom',
            cell: (props) => renderHazmatCell(props.dataItem),
            width: 60,
        },

    ];

    return (
        <Grid container spacing={2}>
            {hazmatDetails &&
                <TgfDialog
                    onClose={() => setHazmatDetails(null)}
                    width={"60%"}
                    title={"Hazmat Details"}
                >
                    <HazmatDetailsDialog hazmatDetails={hazmatDetails} additionalData={additionalData}/>
                </TgfDialog>
            }
            <Grid item xs={12}>
                <TgfDataGrid
                    ref={ref}
                    onLoadDataPage={loadLtlShipmentItems}
                    gridColumns={gridColumns}
                    sort={[{field: "packageTypeName", dir: "asc"}]}
                    pageable={{
                        pageSize: 20,
                        buttonCount: 10,
                        pageSizes: [5, 10, 20, 50, 100, 500]
                    }}
                />
            </Grid>
        </Grid>
    )
})

export default ShipmentContentsListing
