import AppMuiCard from "../../../../../core/components/cards/AppCard";
import {Grid} from "@material-ui/core";
import FieldError from "../../../../../crm/components/inputs/FieldError";
import LabelOrLink from "../../../../../crm/components/cardHelperComponents/LabelOrLink";
import HotNote from "../../../../shipments/DetailsPage/includes/HotNote";
import DateOnlyPicker from "../../../../../core/kendo/inputs/DateOnlyPicker";
import {
    WeekDaysOnlyCalendar
} from "../../../../../core/kendo/form/inputs/customDatePickerCalendars/WeekdaysOnlyCalendar";
import {TgfButtonGroupInput} from "../../../../../core/kendo/form/inputs/TgfButtonGroupInput";
import TgfComboBox from "../../../../../core/kendo/form/inputs/TgfComboBox";
import StringUtils from "../../../../../core/utils/StringUtils/StringUtils";
import TgfTextBox from "../../../../../core/kendo/form/inputs/TgfTextBox";
import React, {useEffect} from "react";
import InfoIconTooltip from "../../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";
import {Label} from "@progress/kendo-react-labels";
import CrmTypedAddressBookListingComboBox from "../../../../../crm/components/CrmTypedAddressBookListingComboBox";

export const OriginCard = (props) => {
    const {
        type,
        formValues,
        setFormValues,
        applyFormValue,
        fieldErrors,
        originLocationTypes = [],
        pickupSiteOptions = [],
        originAccessorialOptions = [],
        setIsDirty,
        defaultShipperContent,
        originZipCodeRef,
    } = props;

    const filterShipperConsignee = (filter) => {
        return {
            ...filter,
            $or :  [
                { companyIsDisabled: false },
                { companyIsDisabled: null }
            ],
            affiliateCompanyId: formValues.customer.companyId,
            companyIsInactive: false,
            primaryContactId: {
                $ne: null
            }
        }
    };

    useEffect(() => {
        if(!formValues.pickupDate && type === "quoteOnly") {
            applyFormValue("pickupDate", new Date());
        }
    }, [type, applyFormValue]);

    const handleChange = (e) => {
        applyFormValue(e.target.name, e.target.value)
    }

    const handleOriginZipChange = async (e) => {
        const clone = {...formValues};
        clone.shipper = defaultShipperContent;
        clone.shipper.companyPostalCode = e.target.value;
        clone.originCountry = StringUtils.isFirstCharNumberOrLetter(e.target.value) === "NUMBER" ? "USA" : "CA";
        clone[e.target.element.name] = e.target.value;
        setFormValues({...clone});
        setIsDirty(true);
    }

    const handlePickupSiteChange = (e) => {
        const clone = {...formValues};
        if(e.target.value?.id) {
            clone.originLocationType = null;
        } else {
            clone.originLocationType = originLocationTypes.find(x => x.display === "Business").id;
        }
        clone.pickupSite = e.target.value?.id;
        setFormValues({...clone});
        setIsDirty(true);
    }

    const handleShipperChange = async (e) => {
        const shipper = e;
        const clone = {...formValues};
        clone.shipper = shipper ? shipper : defaultShipperContent;
        if(shipper?.companyId) clone.shipper.selectedFromFirstPage = true;
        if(shipper?.companyPostalCode) {
            clone.originZipPostal = shipper.companyPostalCode;
            clone.originCountry = StringUtils.isFirstCharNumberOrLetter(clone.originZipPostal) === "NUMBER" ? "USA" : "CA";
        } else {
            clone.originZipPostal = null;
        }
        setFormValues({...clone});
        setIsDirty(true);
    };


    return (
        <AppMuiCard title={'Origin'}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={4}>
                    <Label>Zip/Postal Code</Label>
                    <InfoIconTooltip title={type === "customerQuote" ?
                        "Canadian Postal Codes must be in XXX XXX format where it has a space in the middle.  Otherwise, the quote cannot be ran.  If you selected an existing address as the shipper or consignee with the postal code in the incorrect format, you must update the involved address first, then select the address again accordingly."
                        :
                        "Canadian Postal Codes must be in XXX XXX format where it has a space in the middle.  Otherwise, the quote cannot be ran."
                    }
                        maxWidth={400}
                    />
                    <TgfTextBox
                        id={'originZipPostal'}
                        name={"originZipPostal"}
                        value={formValues.originZipPostal}
                        onChange={handleOriginZipChange}
                        maxLength={7}
                        tabIndex={1}
                        ref={originZipCodeRef}
                    />
                    <FieldError >{fieldErrors.originZipPostal}</FieldError>
                </Grid>
                {type === "customerQuote" ?
                    <>
                        <Grid item className={'centerText'} xs={12} md={12} lg={1}>
                            <br/>
                            <label>OR</label>
                        </Grid>
                        <Grid item className={'boldField'} xs={12} md={12} lg={7}>
                            <LabelOrLink labelText={"Shipper"} selected={formValues.shipper}
                                         styles={{color: '#28a745'}}/>
                            {formValues.shipper?.hotNote &&
                                <HotNote company={formValues.shipper}/>
                            }
                            <CrmTypedAddressBookListingComboBox
                                style={{width: '100%'}}
                                types={[CrmTypedAddressBookListingComboBox.Customer, CrmTypedAddressBookListingComboBox.ShipperConsignee]}
                                value={formValues.shipper.companyId ? formValues.shipper : null}
                                onChange={handleShipperChange}
                                filterMap={filterShipperConsignee}
                                placeholder='Search...'
                                popupIcon={' '}
                                disabled={!formValues.customer}
                                additionalInputProps={{tabindex: 2}}
                            />
                        </Grid>
                    </>
                    :
                    <Grid item xs={12} md={12} lg={8}></Grid>
                }
                <Grid item xs={12} md={12} lg={3}>
                    <DateOnlyPicker
                        tabIndex={3}
                        id={"pickupDate"}
                        name={"pickupDate"}
                        label={"Pickup Date"}
                        value={formValues.pickupDate}
                        onChange={handleChange}
                        min={new Date()}
                        calendar={WeekDaysOnlyCalendar}
                    />
                    <FieldError >{fieldErrors.pickupDate}</FieldError>
                </Grid>
                <Grid item xs={12} md={12} lg={5}>
                    <TgfButtonGroupInput
                        label={"Location Type"}
                        id={"originLocationType"}
                        name={"originLocationType"}
                        value={formValues.originLocationType}
                        data={originLocationTypes}
                        isMultiSelect={false}
                        onChange={handleChange}
                        disabled={(formValues.pickupSite)}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                    <TgfComboBox
                        label={"Non Commercial Pickup Site"}
                        id={"pickupSite"}
                        name={"pickupSite"}
                        data={pickupSiteOptions}
                        value={formValues.pickupSite}
                        onChange={handlePickupSiteChange}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={8}>
                    <TgfButtonGroupInput
                        label={"Accessorials"}
                        id={"originAccessorials"}
                        name={"originAccessorials"}
                        value={formValues.originAccessorials}
                        data={originAccessorialOptions}
                        isMultiSelect={true}
                        onChange={handleChange}
                    />
                </Grid>
            </Grid>
        </AppMuiCard>
    )
}