import AppMuiCard from "../../../../../../core/components/cards/AppCard";
import {Grid} from "@material-ui/core";
import {Label} from "@progress/kendo-react-labels";
import InfoIconTooltip from "../../../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";
import React from "react";
import TgfCurrencyInput from "../../../../../../core/kendo/form/inputs/TgfCurrencyInput";
import FieldError from "../../../../../../crm/components/inputs/FieldError";

export const FinancialsCard = (props) => {

    const {
        formValues,
        applyFormValue,
        fieldErrors,
        disableAll = false,
    } = props;

    const handleChange = (e) => {
        applyFormValue(e.target.name, e.target.value);
    };

    return (
        <AppMuiCard title="Financials">
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={12} md={6}>
                    <Label>
                        Carrier Cost
                    </Label>
                    {/* TODO check on tooltip */}
                    <InfoIconTooltip
                        title={`TBD. Clemmons will provide them later.`}
                    />
                    <TgfCurrencyInput
                        id={"carrierCost"}
                        name={"carrierCost"}
                        value={formValues.selectedQuote?.rate?.amount}
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Label>
                        Customer Cost
                    </Label>
                    {/* TODO check on tooltip */}
                    <InfoIconTooltip
                        title={`TBD. Clemmons will provide them later.`}
                    />
                    <TgfCurrencyInput
                        id={"customerCost"}
                        name={"customerCost"}
                        value={formValues?.customerCost}
                        onChange={handleChange}
                        disabled={disableAll}
                    />
                    <FieldError>{fieldErrors?.customerCost}</FieldError>
                </Grid>
            </Grid>
        </AppMuiCard>
    );
};