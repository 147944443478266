import When from "../../core/components/condtionals/When";
import React from "react";
import useIsAdministrator from "../../hubs/personaV2/selectors/useIsAdministrator";

const WhenAdministrator = props => {
    return (
        <When condition={useIsAdministrator}>
            {props.children}
        </When>
    );
};
export default WhenAdministrator;
